import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FileUploadComponentVariant, FileUploadSession, MijndnbUiModule } from '@dnb/mijndnb-ui';
import { DropdownComponent, LoadingDataIndicatorComponent } from '@dnb/supervision-core';
import {
	UploadFormValuesRequired,
	UploadFormValues,
	initialUploadFormValuesRequired,
	initialUploadFormValues as initialValues,
	UploadForm,
	IOption
} from '../../models';
import { FormControlSelectComponent } from '../form-control-select/form-control-select.component';
import { FormControlDateComponent } from '../form-control-date/form-control-date.component';
import { FormControlTextComponent } from '../form-control-text/form-control-text.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
	selector: 'app-document-upload-form',
	standalone: true,
	imports: [
		CommonModule,
		LoadingDataIndicatorComponent,
		FormControlSelectComponent,
		FormControlDateComponent,
		FormControlTextComponent,
		MatFormFieldModule,
		JsonPipe,
		DropdownComponent,
		MijndnbUiModule,
		TranslateModule,
		ReactiveFormsModule
	],
	templateUrl: './document-upload-form.component.html',
	styleUrls: ['./document-upload-form.component.scss']
})
export class DocumentUploadFormComponent implements OnInit, OnDestroy {
	@Input({ required: true }) uploadSession: FileUploadSession;
	@Input() documentTypeOptions: IOption[] = [];
	@Input() yearOptions: IOption[] = [];

	@Output() valueChange = new EventEmitter<Partial<UploadFormValues>>();
	@Output() onSubmit = new EventEmitter<Partial<UploadFormValues>>();

	private readonly destroy$ = new Subject<void>();
	private _required: UploadFormValuesRequired = initialUploadFormValuesRequired;

	uploadVariant = FileUploadComponentVariant.Normal;
	submitted = false;

	form = new FormGroup<UploadForm>({
		file: new FormControl<string[]>(initialValues.file, { updateOn: 'change', validators: Validators.required }),
		documentType: new FormControl<string | null>(initialValues.documentType, { updateOn: 'change' }),
		year: new FormControl<string | null>(initialValues.year, { updateOn: 'change' }),
		validFrom: new FormControl<moment.Moment | null>(initialValues.validFrom, { updateOn: 'change' }),
		lastDayBookYear: new FormControl<moment.Moment | null>(initialValues.lastDayBookYear, { updateOn: 'change' }),
		regarding: new FormControl<string | null>(initialValues.regarding, { updateOn: 'blur', validators: [Validators.maxLength(50)] })
	});

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.form.valueChanges.pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value) => {
			this.valueChange.emit(value);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	@Input()
	set values(value: Partial<UploadFormValues>) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { file, ...otherValues } = value;
		this.form.patchValue(otherValues, { emitEvent: false });
	}

	@Input()
	set required(value: UploadFormValuesRequired) {
		this._required = value;

		Object.entries(value).forEach((entry) => {
			const [controlName, isRequired] = entry;
			const control = this.form.get(controlName);

			if (isRequired) {
				control?.addValidators([Validators.required]);
			} else {
				control?.removeValidators([Validators.required]);
			}

			control?.updateValueAndValidity();
		});
	}

	get required(): UploadFormValuesRequired {
		return this._required;
	}

	@Input()
	set disabled(value: boolean) {
		if (value) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	submit(): void {
		markAllControlsAsTouched(this.form);
		this.submitted = true;
		const { valid, value } = this.form;
		if (valid) {
			this.onSubmit.emit({ ...value });
		}
	}

	getRegardingLabelForDocumentType(documentTypeName: string | null): string {
		const fallbackToken = 'ORGANISATION_DETAILS.DOCUMENT.REGARDING';

		if (!documentTypeName) {
			return this.translateService.instant(fallbackToken);
		}

		const token = `ORGANISATION_DETAILS.DOCUMENT.${documentTypeName?.toUpperCase()}_REGARDING`;
		return this.getTranslationWithFallback(token, fallbackToken);
	}

	private getTranslationWithFallback(token: string, fallbackToken: string): string {
		const translation = this.translateService.instant(token);
		return translation !== token ? translation : this.translateService.instant(fallbackToken);
	}
}

function markAllControlsAsTouched(formGroup: FormGroup) {
	Object.values(formGroup.controls).forEach((control) => {
		control.markAsTouched();
	});
}
