import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IOrganisationProfileService, MonitoringService, httpHandleError } from 'src/app/shared';
import { IOrganisationProfile } from '../../root/models';

@Injectable({
	providedIn: 'root'
})
export class OrganisationProfileService implements IOrganisationProfileService {
	private profile$: Observable<IOrganisationProfile>;

	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {
		this.profile$ = this.httpClient
			.get<IOrganisationProfile>(`${environment.apiUrl}/v1/organisationdetails/profile`)
			.pipe(catchError(httpHandleError(this.monitoringService)), shareReplay(1));
	}

	getOrganisationProfile(): Observable<IOrganisationProfile> {
		return this.profile$;
	}

	getOrganisationProfileHash(): Observable<string> {
		return this.profile$.pipe(map((profile) => profile.hash));
	}
}
