import { Injectable } from '@angular/core';
import { IDocumentService } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { DownloadProgressEvent, MonitoringService, httpHandleError, trackDownloadProgress } from 'src/app/shared';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { IDocumentType, IDocumentUploadRequest, IDocument, IDocumentResponse, mapResponseToDocuments, DocumentDeleteResponse } from '../models';
import { FileUploadSession } from '@dnb/mijndnb-ui';
import { environment } from 'src/environments/environment';
import { MetadataResponse } from "../models/metadata-response.models";

interface FileUploadSessionResponse {
	sessionId: string;
	validUntil: string;
	uiComponentConfiguration: {
		maxNumberOfFiles: number;
		maxFileSizeInBytes: number;
		maxTotalSizeInBytes: number;
		allowedFileTypes: string[];
	};
}

@Injectable({
	providedIn: 'root'
})
export class DocumentsService implements IDocumentService {
	private cache: { [url: string]: any } = {};

	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {}

	getDocuments(relationId?: string | null): Observable<IDocument[]> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/list/${relationId ?? ''}`;

		return this.httpClient.get<IDocumentResponse>(url).pipe(
			map((response): IDocument[] => mapResponseToDocuments(response)),
			catchError(httpHandleError(this.monitoringService))
		);
	}

	getDocumentTypes(role?: string | null): Observable<IDocumentType[]> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/types/${role ?? ''}`;

		if (this.cache[url]) {
			return of(this.cache[url]);
		}

		return this.httpClient.get<IDocumentType[]>(url).pipe(
			tap((data) => {
				this.cache[url] = data;
			}),
			catchError(httpHandleError(this.monitoringService))
		);
	}

	submitDocumentData(documentData: IDocumentUploadRequest, relationId: string | null): Observable<MetadataResponse> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/metadata/${relationId ?? ''}`;

		return this.httpClient.post<MetadataResponse>(url, documentData).pipe(catchError(httpHandleError(this.monitoringService)));
	}

	createFileUploadSession(role?: string | null): Observable<FileUploadSession> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/uploadsession/${role ?? ''}`;

		return this.httpClient
			.post<FileUploadSessionResponse>(url, {})
			.pipe(catchError(httpHandleError(this.monitoringService)), map(mapToFileUploadSession));
	}

	requestDownload(fileId: string): Observable<void> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/download/${fileId}`;

		return this.httpClient.post<void>(url, null).pipe(catchError(httpHandleError(this.monitoringService)));
	}

	download(fileId: string, fileSize?: number): Observable<DownloadProgressEvent> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/download/${fileId}`;

		return this.httpClient
			.get(url, {
				reportProgress: true,
				observe: 'events',
				responseType: 'blob'
			})
			.pipe(trackDownloadProgress(fileSize), catchError(httpHandleError(this.monitoringService)));
	}

	delete(fileId: string): Observable<DocumentDeleteResponse> {
		const url = `${environment.apiUrl}/v1/organisationdetails/documents/${fileId}`;

		return this.httpClient.delete<DocumentDeleteResponse>(url).pipe(catchError(httpHandleError(this.monitoringService)));
	}

}

function mapToFileUploadSession(response: FileUploadSessionResponse): FileUploadSession {
	const {
		sessionId,
		uiComponentConfiguration: { maxNumberOfFiles, maxFileSizeInBytes, maxTotalSizeInBytes, allowedFileTypes }
	} = response;

	return {
		sessionId,
		maxNumberOfFiles,
		maxFileSizeInBytes,
		maxTotalSizeInBytes,
		allowedFileTypes
	};
}
