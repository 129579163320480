import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PageHeaderService {
	private readonly pageTitle = new BehaviorSubject<string | null>('');
	pageTitle$ = this.pageTitle.asObservable();

	public setPageHeaderTitle(title: string | null): void {
		this.pageTitle.next(title);
	}
}
