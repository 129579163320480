<form *ngIf="formGroup" [formGroup]="formGroup">
	<mat-form-field [hideRequiredMarker]="!label" [floatLabel]="'always'" [appearance]="'outline'">
		<!-- label -->
		<mat-label *ngIf="label">
			{{ label | translate }}
		</mat-label>

		<!-- input -->
		<input matInput [formControl]="control" />

		<!-- error -->
		<mat-error *ngIf="control.hasError('required')">
			{{ errorRequired | translate }}
		</mat-error>
		<mat-error *ngIf="control.hasError('maxlength')">
			{{ 'ERRORS.MAX_LENGTH' | translate: { maxLength: control.getError('maxlength').requiredLength } }}
		</mat-error>
	</mat-form-field>
</form>
