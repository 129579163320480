import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { runInZone } from '../utils/operator.function';

interface AuthBroadcastMessage {
	type: string;
	payload: any;
}

@Injectable({
	providedIn: 'root',
	deps: [NgZone]
})
export class AuthBroadcastService {
	private broadcastChannel: BroadcastChannel;
	private onMessage = new Subject<any>();
	private subscription = new Subscription();

	constructor(private ngZone: NgZone) {
		this.broadcastChannel = new BroadcastChannel('organisation-details-app-auth-broadcast-channel');
		this.broadcastChannel.onmessage = (message): void => {
			this.onMessage.next(message.data);
		};
	}

	publish(message: AuthBroadcastMessage): void {
		this.broadcastChannel.postMessage(message);
	}

	messagesOfType(type: string): Observable<AuthBroadcastMessage> {
		return this.onMessage.pipe(
			runInZone(this.ngZone),
			filter((message) => message.type === type)
		);
	}

	init(): Promise<void> {
		return new Promise<void>((resolve) => {
			this.subscription.add(
				this.messagesOfType('single_signout').subscribe(() => {
					sessionStorage.clear();
					window.location.href = environment.authenticationServer.postLogoutRedirectUri;
				})
			);

			resolve();
		});
	}
}

export function InitializeAuthBroadcastService(authBroadcastService: AuthBroadcastService) {
	return (): Promise<void> => {
		return authBroadcastService.init();
	};
}
