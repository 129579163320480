import { Injectable } from '@angular/core';
import { IDocumentService } from '../interfaces';
import { Observable, concat, delay, interval, map, of, take } from 'rxjs';
import { DocumentDeleteResponse, IDocument, IDocumentResponse, IDocumentType, IDocumentUploadRequest, mapResponseToDocuments } from '../models';
import { FileUploadSession } from '@dnb/mijndnb-ui';
import { HttpClient } from '@angular/common/http';
import { DownloadProgressEvent, MonitoringService, ObjectHelperService } from 'src/app/shared';

@Injectable({
	providedIn: 'root'
})
export class DocumentsMockedService implements IDocumentService {
	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {}

	getDocuments(relationId?: string | null): Observable<IDocument[]> {
		console.log('DocumentsMockedService::getDocuments relationId: ', relationId);

		const mockedResponse: IDocumentResponse = getMockedDocuments();
		const documents: IDocument[] = mapResponseToDocuments(mockedResponse);

		return of(documents).pipe(delay(250));
	}

	getDocumentTypes(role?: string | null): Observable<IDocumentType[]> {
		console.log('DocumentsMockedService::getMyDocumentTypes called with role: ', role);
		const mockedDocumentTypes: IDocumentType[] = getMockedDocumentTypes();

		return of(mockedDocumentTypes).pipe(delay(750));
	}

	submitDocumentData(documentData: IDocumentUploadRequest, relationId: string | null): Observable<unknown> {
		console.group('DocumentsMockedService::submitDocumentData called with:');
		console.log('- documentData', documentData);
		console.log('- relationId: ', relationId);
		console.groupEnd();

		return of();
	}

	createFileUploadSession(): Observable<FileUploadSession> {
		console.log('DocumentsMockedService::createFileUploadSession called');
		const mockedFileUploadSession = getMockedFileUploadSession();

		return of(mockedFileUploadSession).pipe(delay(1000));
	}

	requestDownload(fileId: string): Observable<void> {
		console.log('DocumentsMockedService::requestFile fileId: ', fileId);

		return of(null as void).pipe(take(1), delay(1000));
	}

	download(fileId: string): Observable<DownloadProgressEvent> {
		console.log('DocumentsMockedService::download fileId: ', fileId);

		const started$: Observable<DownloadProgressEvent> = of({
			status: 'started',
			progress: 0,
			blob: null,
			fileName: null
		});

		const progress$: Observable<DownloadProgressEvent> = interval(100).pipe(
			take(10),
			map(
				(index): DownloadProgressEvent => ({
					status: 'progress',
					progress: (index + 1) * 10,
					blob: null,
					fileName: null
				})
			)
		);

		const completed$: Observable<DownloadProgressEvent> = of({
			status: 'completed',
			progress: 100,
			blob: new Blob(),
			fileName: null
		});

		return concat(started$, progress$, completed$);
	}

	delete(fileId: string): Observable<DocumentDeleteResponse> {
		console.log('DocumentsMockedService::delete fileId: ', fileId);

		return null;
	}
}

function getMockedDocuments(): IDocumentResponse {
	return {
		documentInfoList: [
			{
				fileId: 'ENCRYPTED-FILE-ID-001',
				title: 'Jaarverslag 22-01-2024',
				fileName: 'Jaarverslag 22-01-2024.pdf',
				contentType: null,
				readyForDownload: true,
				deleteDocumentEnabled: true,
				metadata: {
					documentDate: '2024-01-22T00:00:00+01:00',
					documentType: 'Jaarverslag',
					externalFilename: 'jaarverslag 001.pdf',
					submittedOn: '2024-01-22T15:42:16.9730196+01:00',
					year: 2024
				}
			},
			{
				fileId: 'ENCRYPTED-FILE-ID-002',
				title: 'Accountantsverslag 01-03-2024',
				fileName: 'Accountantsverslag 01-03-2024.pdf',
				contentType: null,
				readyForDownload: true,
				deleteDocumentEnabled: true,
				metadata: {
					documentDate: '2024-03-01T00:00:00+01:00',
					documentType: 'Accountantsverslag',
					externalFilename: 'accountverslag 001.pdf',
					submittedOn: '2024-01-23T16:31:19.7446217+01:00',
					year: 2024
				}
			},
			{
				fileId: 'ENCRYPTED-FILE-ID-003',
				title: 'Herstelplan 05-01-2024',
				fileName: 'Herstelplan 05-01-2024.pdf',
				contentType: null,
				readyForDownload: true,
				deleteDocumentEnabled: true,
				metadata: {
					documentDate: '2024-01-05T00:00:00+01:00',
					documentType: 'Herstelplan',
					externalFilename: 'herstelplan 001.pdf',
					submittedOn: '2024-01-23T18:09:38.5740533+01:00',
					year: 2024
				}
			}
		]
	};
}

function getMockedDocumentTypes(): IDocumentType[] {
	return [
		{
			id: 'DOCUMENT-TYPE-ID-001',
			lastDayBookYearRequired: true,
			lastPeriodShown: 'LastDocument',
			name: 'Jaarverslag',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: false,
			yearRequired: false,
			supervisionExecutionLabel: 'Financial reporting'
		},
		{
			id: 'DOCUMENT-TYPE-ID-002',
			lastDayBookYearRequired: true,
			lastPeriodShown: 'LastDocument',
			name: 'Jaarrekening',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: false,
			yearRequired: false,
			supervisionExecutionLabel: 'Financial reporting'
		},
		{
			id: 'DOCUMENT-TYPE-ID-003',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Organogram',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Internal governance and risk management'
		},
		{
			id: 'DOCUMENT-TYPE-ID-003',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Legal entity chart',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Internal governance and risk management'
		},
		{
			id: 'DOCUMENT-TYPE-ID-004',
			lastDayBookYearRequired: true,
			lastPeriodShown: 'LastDocument',
			name: 'Verklaring van de externe accountant',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: false,
			yearRequired: false,
			supervisionExecutionLabel: 'Financial reporting'
		},
		{
			id: 'DOCUMENT-TYPE-ID-004',
			lastDayBookYearRequired: true,
			lastPeriodShown: 'LastDocument',
			name: 'Accountantsverslag',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: false,
			yearRequired: false,
			supervisionExecutionLabel: 'Financial reporting'
		},
		{
			id: 'DOCUMENT-TYPE-ID-005',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Statuten',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Internal governance and risk management'
		},
		{
			id: 'DOCUMENT-TYPE-ID-006',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Exitplan',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Recovery and resolution'
		},
		{
			id: 'DOCUMENT-TYPE-ID-007',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Herstelplan',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Recovery and resolution'
		},
		{
			id: 'DOCUMENT-TYPE-ID-007',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastYear',
			name: 'ICAAP',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'SREP'
		},
		{
			id: 'DOCUMENT-TYPE-ID-008',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastYear',
			name: 'ILAAP',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'SREP'
		},
		{
			id: 'DOCUMENT-TYPE-ID-009',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastYear',
			name: 'Shareholderregister',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Grouprelated subjects'
		},
		{
			id: 'DOCUMENT-TYPE-ID-010',
			lastDayBookYearRequired: false,
			lastPeriodShown: 'LastDocument',
			name: 'Consolidation scopes',
			regardingRequired: false,
			uploadEnabled: true,
			validFromRequired: true,
			yearRequired: false,
			supervisionExecutionLabel: 'Grouprelated subjects'
		}
	];
}

function getMockedFileUploadSession(): FileUploadSession {
	return {
		sessionId: 'mocked-session-id',
		maxNumberOfFiles: 1,
		maxFileSizeInBytes: 1024,
		maxTotalSizeInBytes: 1024,
		allowedFileTypes: ['PDF']
	};
}
