export enum HttpErrorCode {
	BackendUnauthorized = '401.0',
	ReadScopeNotVerified = '403.0',
	WriteScopeNotVerified = '403.1',
	OrganisationNotFound = '404.0',
	OrganisationDuplicates = '409.0',
	HasNoCocNumber = '428.0',
	NoMenuConfiguration = '428.1',
	Unknown = '500.0',
	BackendReturnedNull = '500.1'
}

const translationTokens: { [key in HttpErrorCode]: string } = {
	[HttpErrorCode.BackendUnauthorized]: 'ORGANISATION_DETAILS.ERRORS.BACKEND_UNAUTHORIZED',
	[HttpErrorCode.ReadScopeNotVerified]: 'ORGANISATION_DETAILS.ERRORS.READ_SCOPE_NOT_VERIFIED',
	[HttpErrorCode.WriteScopeNotVerified]: 'ORGANISATION_DETAILS.ERRORS.WRITE_SCOPE_NOT_VERIFIED',
	[HttpErrorCode.OrganisationNotFound]: 'ORGANISATION_DETAILS.ERRORS.ORGANISATION_NOT_FOUND',
	[HttpErrorCode.OrganisationDuplicates]: 'ORGANISATION_DETAILS.ERRORS.ORGANISATION_DUPLICATES',
	[HttpErrorCode.HasNoCocNumber]: 'ORGANISATION_DETAILS.ERRORS.HAS_NO_COC_NUMBER',
	[HttpErrorCode.NoMenuConfiguration]: 'ORGANISATION_DETAILS.ERRORS.NO_MENU_CONFIGURATION',
	[HttpErrorCode.Unknown]: 'ORGANISATION_DETAILS.ERRORS.UNKNOWN',
	[HttpErrorCode.BackendReturnedNull]: 'ORGANISATION_DETAILS.ERRORS.BACKEND_RETURNED_NULL'
};

export function getTranslationTokenFromHttpErrorCode(code: HttpErrorCode): string {
	return translationTokens[code] ?? translationTokens[HttpErrorCode.Unknown];
}
