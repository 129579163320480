import { MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { LanguageService } from 'src/app/language.service';
import { environment } from 'src/environments/environment';

export function MSALGuardConfigFactory(languageService: LanguageService): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			extraQueryParameters: {
				idps: environment.authenticationServer.idpFilter.join(','),
				ui_locales: languageService.currentLang()
			}
		}
	};
}
