<div class="{{ barStyleClass }}">
	<div class="content-right">
		<span>
			<!-- prevent form submit by setting type to button: http://w3c.github.io/html-reference/button.html -->
			<dlt-forms-button (clicked)="onCancelAndClose()" [buttonAttribute]="CancelButton"></dlt-forms-button>
		</span>
		&nbsp;
		<ng-container *hideIfUnauthorized="permissionType">
			<span *ngIf="!saveHidden">
				<dlt-forms-button [buttonAttribute]="SubmitButton"></dlt-forms-button>
			</span>
		</ng-container>
	</div>
</div>
