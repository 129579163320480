import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { InfoBoxComponent, InfoButtonComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { ContactChannelEditBaseComponent } from '../contact-channel-edit-base.component';
import { FormAttribute } from '@dnb/supervision-core';
import { IEmailAddress, IOrganisationObject } from 'src/app/shared/models';
import { OrganisationEntityFormFactory } from 'src/app/shared/services/factories/organisation-entity-form.factory';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationEntityEditPropertiesComponent } from '../../organisation-entity-edit-properties/organisation-entity-edit-properties.component';

@Component({
	selector: 'app-email-address-contact-channel-edit',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, InfoBoxComponent, InfoButtonComponent, TranslateModule, OrganisationEntityEditPropertiesComponent],
	templateUrl: './email-address-contact-channel-edit.component.html',
	styleUrls: ['./email-address-contact-channel-edit.component.scss']
})
export class EmailAddressContactChannelEditComponent extends ContactChannelEditBaseComponent {
	@Input() contactChannel!: IEmailAddress;

	@Output() includeEmailAddressCheckedChanged = new EventEmitter<{ formGroup: UntypedFormGroup; emailAddress: IEmailAddress; checked: boolean }>();

	constructor(private formFactory: OrganisationEntityFormFactory) {
		super();
	}

	onCheckedChanged($event: any) {
		const obj = {
			formGroup: this.formGroup,
			emailAddress: this.contactChannel,
			checked: $event.target.checked
		};

		this.includeEmailAddressCheckedChanged.emit(obj);
	}

	protected getOrganisationObjectAfterInit(): IOrganisationObject {
		return this.contactChannel;
	}

	protected createFormAttributes(): FormAttribute[] {
		return this.formFactory.CreateEmailAddressFormAttributes(this.contactChannel, true);
	}
}
