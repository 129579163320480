import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, filter, last, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { ButtonAttribute, FormsButtonComponent, NotificationBoxComponent } from '@dnb/supervision-core';
import { saveAs } from 'file-saver';
import { DownloadProgressEvent, HttpServiceError } from 'src/app/shared';
import { DocumentDownloadModalContext } from '../../models';
import { DOCUMENTS_HUB_SERVICE, DOCUMENTS_SERVICE } from '../../dependency-injection';
import { IDocumentService, IDocumentsHubService } from '../../interfaces';
import { FileReadyForDownloadEvent } from '../../events';

@Component({
	selector: 'app-document-download-modal',
	standalone: true,
	imports: [CommonModule, MatProgressBarModule, NotificationBoxComponent, FormsButtonComponent, MatDialogModule, TranslateModule, JsonPipe],
	templateUrl: './document-download-modal.component.html',
	styleUrls: ['./document-download-modal.component.scss']
})
export class DocumentDownloadModalComponent implements OnInit, OnDestroy {
	private readonly destroy$ = new Subject<void>();

	connected: boolean | null;
	fileName: string | null;
	downloadStatus: 'preparing' | 'downloading' | 'completed' | 'error' = 'preparing';
	progress: number | null;
	error: string = '';

	constructor(
		@Inject(DOCUMENTS_SERVICE) private documentService: IDocumentService,
		@Inject(DOCUMENTS_HUB_SERVICE) private documentsHubService: IDocumentsHubService,
		private dialogRef: MatDialogRef<DocumentDownloadModalComponent>,
		@Inject(MAT_DIALOG_DATA) private dataContext: DocumentDownloadModalContext
	) {}

	ngOnInit(): void {
		const { fileName, fileId, connected } = this.dataContext;
		this.connected = connected;
		this.fileName = fileName;

		if (connected) {
			this.requestAndDownloadFile(fileId);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onClose(): void {
		this.dialogRef.close();
	}

	get cancelButtonAttribute(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'medium',
			iconLeft: 'fa fa-times',
			showIconLeft: true,
			text: this.downloadStatus === 'completed' || this.downloadStatus === 'error' ? 'FORM.CLOSE' : 'FORM.CANCEL',
			disabled: false
		};
	}

	private requestAndDownloadFile(fileId: string): void {
		const requestFile$ = this.documentService.requestDownload(fileId);

		requestFile$
			.pipe(
				switchMap(() => this.waitForFileReadyForDownloadEvent(fileId)),
				switchMap((event) => this.downloadFile(event.fileId))
			)
			.subscribe({
				next: ({ blob, fileName }) => {
					if (blob) {
						saveAs(blob, fileName ?? `${this.fileName}.pdf`);
					}
					setTimeout(() => this.onClose(), 2000);
				},
				error: (error: Error) => {
					this.downloadStatus = 'error';
					this.error = error instanceof HttpServiceError ? error.translationToken : error.message;
				}
			});
	}

	private waitForFileReadyForDownloadEvent(fileId: string): Observable<FileReadyForDownloadEvent> {
		return this.documentsHubService.fileReadyForDownload$.pipe(
			takeUntil(this.destroy$),
			filter((event) => event.fileId === fileId),
			map((event) => {
				const { success } = event;
				if (!success) {
					throw new Error('ORGANISATION_DETAILS.DOCUMENT_DOWNLOAD_MODAL.PREPARE_DOWNLOADING_FAILED');
				}
				return event;
			}),
			take(1)
		);
	}

	private downloadFile(fileId: string, fileSize?: number): Observable<DownloadProgressEvent> {
		return this.documentService.download(fileId, fileSize).pipe(
			takeUntil(this.destroy$),
			tap(({ status, progress }) => {
				switch (status) {
					case 'started':
						this.downloadStatus = 'downloading';
						break;
					case 'progress':
						this.progress = progress;
						break;
					case 'completed':
						this.downloadStatus = 'completed';
						break;
				}
			}),
			last()
		);
	}
}
