<div class="col-xs-12 col-lg-3 corp-strip-section__sidebar" data-test="home_lbl_relevant">
	<h2>{{ 'SUPMOG.TITLE' | translate }}</h2>
	<ul class="sidebar linklist">
		<li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			<a routerLink="./home" class="btn btn--linklist">
				<app-sidebar-arrow></app-sidebar-arrow>
				<span class="btn__text">
					{{ 'SUPMOG.HOME.LINK.TEXT' | translate }}
				</span>
			</a>
		</li>
		<li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
			<a routerLink="./details" class="btn btn--linklist">
				<app-sidebar-arrow></app-sidebar-arrow>
				<span class="btn__text">
					{{ 'SUPMOG.DETAILS.LINK.TEXT' | translate }}
				</span>
			</a>
		</li>
		<li *ngIf="featureAvailable('documents')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			<a routerLink="./documents" class="btn btn--linklist">
				<app-sidebar-arrow></app-sidebar-arrow>
				<span class="btn__text">
					{{ 'SUPMOG.DOCUMENTS.LINK.TEXT' | translate }}
				</span>
			</a>
		</li>
		<li *ngIf="featureAvailable('shareholders')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
			<a routerLink="./shareholders" class="btn btn--linklist">
				<app-sidebar-arrow></app-sidebar-arrow>
				<span class="btn__text">
					{{ 'SUPMOG.SHAREHOLDERS.LINK.TEXT' | translate }}
				</span>
			</a>
		</li>
		<li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
			<a routerLink="./feedback" class="btn btn--linklist">
				<app-sidebar-arrow></app-sidebar-arrow>
				<span class="btn__text">
					{{ 'SUPMOG.FEEDBACK.LINK.TEXT' | translate }}
				</span>
			</a>
		</li>
	</ul>
</div>
