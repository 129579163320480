import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAttribute, FormsButtonComponent } from '@dnb/supervision-core';

@Component({
	selector: 'app-edit-button',
	standalone: true,
	imports: [CommonModule, FormsButtonComponent],
	templateUrl: './edit-button.component.html'
})
export class EditButtonComponent {
	@Input() buttonAttribute: ButtonAttribute;
	@Output() clicked = new EventEmitter<void>();

	protected activeButtonAttributes: ButtonAttribute;

	ngOnInit(): void {
		// set initial attributes
		this.setActiveButtonAttributes();
	}

	protected onClicked(): void {
		this.clicked.emit();
	}

	private setActiveButtonAttributes(): void {
		this.activeButtonAttributes = { ...this.buttonAttribute };
	}
}
