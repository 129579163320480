import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FormOption, IOptionSetService, LikertAttribute, OptionSetAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/app/core/config';
import { MonitoringService } from './monitoring.service';
import { Option } from '../models';

interface IOptionSet {
	labels: { [index: string]: string };
	optionSetLogicalName: string | null;
	optionSetValue: string | null;
	resourceKey: string | null;
}

@Injectable({ providedIn: 'root' })
export class OptionSetService implements IOptionSetService {
	private cache: { [key: string]: FormOption[] } = {};

	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {}

	getOptionSetText(entityName: string, fieldName: string, id: string): string {
		const cacheKey = this.generateCacheKey(entityName, fieldName);
		const cachedOptions = this.cache[cacheKey];

		if (cachedOptions) {
			const result = cachedOptions.find((item) => item.id === id);
			return result ? (result as any).text : '';
		}

		return '';
	}

	getOptionSet(entityName: string, fieldName: string): Observable<FormOption[]> {
		const cacheKey = this.generateCacheKey(entityName, fieldName);
		const cachedOptions = this.cache[cacheKey];

		if (cachedOptions) {
			return of(cachedOptions);
		}

		return this.loadOptionSet(entityName, fieldName).pipe(
			map((optionSet) => this.mapOptionSetToFormOptions(optionSet)),
			tap((formOptions) => {
				if (Array.isArray(formOptions) && formOptions.length > 0) {
					this.cache[cacheKey] = formOptions;
				}
			})
		);
	}

	getOptionSetFromAttribute(attribute: OptionSetAttribute): Observable<FormOption[]> {
		return of(attribute.options.map(this.mapOptionToFormOption));
	}

	getOptionsFromLikertAttribute(attribute: LikertAttribute): Observable<FormOption[]> {
		return of(attribute.options.map(this.mapOptionToFormOption));
	}

	private generateCacheKey(entityName: string, fieldName: string): string {
		return `${entityName}|${fieldName}`;
	}

	private loadOptionSet(entityName: string, fieldName: string): Observable<IOptionSet[]> {
		const url = `${environment.apiUrl}${CONFIG.baseUrls.organisation}/resources/optionsets/${entityName}/${fieldName}`;

		return this.httpClient.get<IOptionSet[]>(url).pipe(
			catchError((error: Error) => {
				// Log the exception and return an empty array in case of an error
				this.monitoringService.logException(error);

				return of([]);
			})
		);
	}

	private mapOptionToFormOption(option: Option): FormOption {
		const { value: id = null, label: description } = option ?? {};

		return { id, description };
	}

	private mapOptionSetToFormOptions(optionSet: IOptionSet[]): FormOption[] {
		return optionSet.map((option): FormOption => {
			const { optionSetValue: id, resourceKey: description } = option ?? {};

			return { id, description };
		});
	}
}
