import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAttribute } from '@dnb/supervision-core';
import { IDocument } from '../../models';
import { EditButtonComponent } from 'src/app/shared/components/edit-button/edit-button.component';
import { ButtonSize } from "src/app/shared";

@Component({
	selector: 'app-document-delete-button',
	standalone: true,
	imports: [CommonModule, EditButtonComponent],
	templateUrl: './document-delete-button.component.html',
	styleUrls: ['./document-delete-button.component.scss']

})
export class DocumentDeleteButtonComponent {
	@Input() document: IDocument;
	@Input() size: ButtonSize;
	@Output() clicked = new EventEmitter<IDocument>();

	get hasId(): boolean {
		return this.document?.fileId != null;
	}

	buttonAttribute(size: ButtonSize): ButtonAttribute {
		if(size === ButtonSize.Small) {
			return <ButtonAttribute>{
				size: 'extra-small',
				iconLeft: 'fa fa-trash',
				showIconLeft: true,
				tooltip: 'ORGANISATION_DETAILS.DOCUMENTS.DELETE'	
			};
		}
		else {
			return <ButtonAttribute>{
				size: 'medium',
				iconLeft: 'fa fa-trash',
				showIconLeft: true,
				text: 'FORM.DELETE',
				};
			}
	}

	onClick(): void {
		this.clicked.emit(this.document);
	}
}
