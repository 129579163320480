<div [formGroup]="formGroup">
	<!--div wrapper for better outlining-->
	<div class="row">
		<div class="col-xs-12">
			<input
				type="checkbox"
				(change)="onCheckedChanged($event)"
				[checked]="contactChannel.showAddress"
				class="input-cc"
				*ngIf="contactChannel.isOptional" />
			<span *ngIf="contactChannel.entityMapping.typeLabelResourceKey" class="contact-channel-header">{{
				contactChannel.entityMapping.typeLabelResourceKey | translate
			}}</span>
			<dlt-info-button *ngIf="hasHelpInformation" (toggle)="onToggleHelpInformation()"></dlt-info-button>
		</div>
	</div>
	<hr class="address mdnb-line" />
	<dlt-info-box
		*ngIf="showHelpInformation"
		[content]="helpInformation"
		[translateContent]="true"
		(closeBox)="onCloseHelpInformation()"></dlt-info-box>
	<ng-container *ngIf="contactChannel.showAddress">
		<div *ngIf="poBoxNumberEditable" class="container-postboxnumber">
			<div class="row">
				<div class="col-xs-12 col-sm-4">
					<label class="control-label">{{ 'LBLMOG-USE-PSTBXNR-1' | translate }}</label>
				</div>
				<div class="col-xs-12 col-sm-8 container-input-cc">
					<input
						type="radio"
						name="usePoBoxNumber{{ contactChannel.id }}"
						[ngModel]="contactChannel.isPostBox"
						[ngModelOptions]="{ standalone: true }"
						[value]="true"
						class="input-cc"
						(change)="onUsePoBoxNumberChanged(true)" />{{ 'FORM.BOOLEAN_YES' | translate }}
					<input
						type="radio"
						name="usePoBoxNumber{{ contactChannel.id }}"
						[ngModel]="contactChannel.isPostBox"
						[ngModelOptions]="{ standalone: true }"
						[value]="false"
						class="input-cc"
						(change)="onUsePoBoxNumberChanged(false)" />{{ 'FORM.BOOLEAN_NO' | translate }}
				</div>
			</div>
		</div>
		<app-organisation-entity-edit-properties [formAttributes]="FormAttributes" [formGroup]="formGroup"> </app-organisation-entity-edit-properties>
	</ng-container>
</div>
