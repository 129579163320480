// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	apiUrl: '__##env.apiUrl##__',
	loginRedirectUri: '__##env.loginRedirectUri##__',
	appInsights: {
		instrumentationKey: '__##env.appInsights.instrumentationKey##__'
	},
	authenticationServer: {
		policies: {
			signUpSignIn: 'b2c_1a_mijndnb'
		},
		authority: '__##env.authentication.authority##__',
		knownAuthorities: ['__##env.authentication.knownAuthorities##__'],
		postLogoutRedirectUri: '__##env.authentication.postLogoutRedirectUri##__',
		logoutUri: '__##env.authentication.logoutUri##__',
		redirectUri: '__##env.authentication.redirectUri##__',
		clientId: '__##env.authentication.clientId##__',
		scopes: ['openid', '__##env.authentication.apiScopeRead##__', '__##env.authentication.apiScopeWrite##__'],
		idpFilter: ['local', 'eherkenning']
	},
	mijndnb: {
		mijnDNBBaseUri: '__##env.mijnDNB.baseUri##__',
		mijnDNBApiUri: '__##env.mijnDNB.apiUri##__',
		mijnDNBCdnUri: '', // Placeholder, used only locally
		userProfileApiScope: '__##env.mijnDNB.userProfileApiScope##__'
	},
	idleInSeconds: '__##env.idleInSeconds##__',
	timeOutInSeconds: '__##env.timeOutInSeconds##__'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * // import "zone.js/plugins/zone-error";  // Included with Angular CLI.
 */
