import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalRHubConnectionState } from 'src/app/core';
import { InfoBoxComponent } from '@dnb/supervision-core';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs';

const DEBOUNCE_TIME_MS = 1500;

@Component({
	selector: 'app-live-update-notification',
	standalone: true,
	imports: [CommonModule, InfoBoxComponent],
	templateUrl: './live-update-notification.component.html',
	styleUrls: ['./live-update-notification.component.scss']
})
export class LiveUpdateNotificationComponent implements OnDestroy {
	private readonly destroy$ = new Subject<void>();
	private stateSubject = new Subject<SignalRHubConnectionState>();
	private errorSubject = new Subject<Error | undefined>();

	state: SignalRHubConnectionState | null;
	error: Error | null;
	show = true;

	constructor() {
		const state$ = this.stateSubject.pipe(takeUntil(this.destroy$), startWith(null));
		const error$ = this.errorSubject.pipe(takeUntil(this.destroy$), startWith(null));

		combineLatest([state$, error$])
			.pipe(distinctUntilChanged(), debounceTime(DEBOUNCE_TIME_MS))
			.subscribe(([state, error]) => {
				this.state = state;
				this.error = error;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	@Input('state')
	set setStatus(value: SignalRHubConnectionState | null) {
		this.stateSubject.next(value);
	}

	@Input('error')
	set setError(value: Error | null) {
		this.errorSubject.next(value);
	}

	onClose(): void {
		this.show = false;
	}
}
