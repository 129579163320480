<ng-container *ngIf="showUI(); else loadingTemplate">
	<mdb-main-navigation></mdb-main-navigation>
	<dlt-breadcrumb></dlt-breadcrumb>

	<ng-container *ngIf="!error; else errorTemplate">
		<main class="content-wrapper" id="main">
			<router-outlet></router-outlet>
		</main>
	</ng-container>

	<mdb-footer></mdb-footer>
</ng-container>

<ng-template #loadingTemplate>
	<dlt-app-loader></dlt-app-loader>
</ng-template>

<ng-template #errorTemplate>
	<main class="content-wrapper" id="main">
		<div class="container">
			<div class="component-main-content">
				{{ error | translate }}
			</div>
		</div>
	</main>
</ng-template>
