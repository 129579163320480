import { Injectable } from '@angular/core';
import { IFieldMapping } from '../../models/field-mapping.model';
import { FormAttributeFactoryService } from './form-attribute-factory-service';
import { IAddress, IEmailAddress, IPhoneNumber } from '../../models/contact-channel.model';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { DateTimeService } from '@dnb/supervision-core/src/lib/dlt-ui';
import { IOrganisationDetails } from '../../models/organisation-details.model';
import { IDataModel } from '../../models/data-model.model';
import { IOrganisationRelation, IOrganisationPersonRelation } from '../../../relations/models/organisation-relation.model';
import { IDataModelProperty } from '../../models/data-model-property.model';
import { Option } from '../../models/option.model';
import { OptionSetAttribute } from '../../models/attributes/optionset-attribute.model';
import { TextAttribute } from '../../models/attributes/text-attribute.model';

export class FieldMappings {
	private _map: any;

	constructor(fieldMappings: IFieldMapping[]) {
		this._map = new Object();

		if (fieldMappings == null) {
			return;
		}

		fieldMappings.forEach((fm) => {
			this._map[fm.propertyName] = fm;
		});
	}

	GetFieldMapping(name: string): IFieldMapping {
		return this._map[name] as IFieldMapping;
	}
}

@Injectable({
	providedIn: 'root'
})
export class OrganisationEntityFormFactory {
	constructor(
		private formAttributeFactory: FormAttributeFactoryService,
		public dateTimeService: DateTimeService
	) {}

	public CreateEmailAddressFormAttributes(emailAddress: IEmailAddress, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (emailAddress == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(emailAddress.fieldMappings);
		const fieldMapping = fieldMappings.GetFieldMapping('address');
		if (fieldMapping != null) {
			if (editMode && fieldMapping && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute('address', 'ORGANISATION_DETAILS.CONTACT_CHANNELS.EMAIL', fieldMapping, emailAddress.address)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('address', 'ORGANISATION_DETAILS.CONTACT_CHANNELS.EMAIL', fieldMapping, emailAddress.address)
				);
			}
		}

		return attrs;
	}

	public CreatePhoneNumberFormAttributes(phoneNumber: IPhoneNumber, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (phoneNumber == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(phoneNumber.fieldMappings);
		const fieldMapping = fieldMappings.GetFieldMapping('number');
		if (fieldMapping != null) {
			const isMobile = phoneNumber.isMobile === true;
			const label = isMobile ? 'ORGANISATION_DETAILS.CONTACT_CHANNELS.MOBILE' : 'ORGANISATION_DETAILS.CONTACT_CHANNELS.PHONE';

			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('number', label, fieldMapping, phoneNumber.number));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('number', label, fieldMapping, phoneNumber.number));
			}
		}

		return attrs;
	}

	public CreatePostalAddressFormAttributes(postalAddress: IAddress, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (postalAddress == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(postalAddress.fieldMappings);

		let fieldMapping: IFieldMapping = null!;

		if (!postalAddress.isPostBox) {
			fieldMapping = fieldMappings.GetFieldMapping('street');
			if (fieldMapping != null) {
				if (editMode && fieldMapping.isModifiable) {
					attrs.push(
						this.formAttributeFactory.CreateTextAttribute(
							'street',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.STREET',
							fieldMapping,
							postalAddress.street
						)
					);
				} else {
					attrs.push(
						this.formAttributeFactory.CreateLabelAttribute(
							'street',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.STREET',
							fieldMapping,
							postalAddress.street
						)
					);
				}
			}

			fieldMapping = fieldMappings.GetFieldMapping('houseNumber');
			if (fieldMapping != null) {
				if (editMode && fieldMapping.isModifiable) {
					attrs.push(
						this.formAttributeFactory.CreateTextAttribute(
							'houseNumber',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.HOUSENUMBER',
							fieldMapping,
							postalAddress.houseNumber
						)
					);
				} else {
					attrs.push(
						this.formAttributeFactory.CreateLabelAttribute(
							'houseNumber',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.HOUSENUMBER',
							fieldMapping,
							postalAddress.houseNumber,
							postalAddress.houseNumberAddition
						)
					);
				}
			}

			fieldMapping = fieldMappings.GetFieldMapping('houseNumberAddition');
			if (fieldMapping != null && editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'houseNumberAddition',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.HOUSENUMBER',
						fieldMapping,
						postalAddress.houseNumberAddition
					)
				);
			}
		} else {
			fieldMapping = fieldMappings.GetFieldMapping('postOfficeBox');
			if (fieldMapping != null) {
				if (editMode && fieldMapping.isModifiable) {
					attrs.push(
						this.formAttributeFactory.CreateTextAttribute(
							'postOfficeBox',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.POBOX',
							fieldMapping,
							postalAddress.postOfficeBox
						)
					);
				} else {
					attrs.push(
						this.formAttributeFactory.CreateLabelAttribute(
							'postOfficeBox',
							'ORGANISATION_DETAILS.CONTACT_CHANNELS.POBOX',
							fieldMapping,
							postalAddress.postOfficeBox
						)
					);
				}
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('location');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'location',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.LOCATION',
						fieldMapping,
						postalAddress.location
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'location',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.LOCATION',
						fieldMapping,
						postalAddress.location
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('postalcode');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'postalcode',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.POSTALCODE',
						fieldMapping,
						postalAddress.postalcode
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'postalcode',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.POSTALCODE',
						fieldMapping,
						postalAddress.postalcode
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('city');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute('city', 'ORGANISATION_DETAILS.CONTACT_CHANNELS.CITY', fieldMapping, postalAddress.city)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('city', 'ORGANISATION_DETAILS.CONTACT_CHANNELS.CITY', fieldMapping, postalAddress.city)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('country');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'country',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.COUNTRY',
						fieldMapping,
						postalAddress.country
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'country',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.COUNTRY',
						fieldMapping,
						postalAddress.country
					)
				);
			}
		}

		return attrs;
	}

	public CreateOrganisationDetailsFormAttributes(details: IOrganisationDetails, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (details == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(details.fieldMappings);
		let fieldMapping = fieldMappings.GetFieldMapping('kvk');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('kvk', 'ORGANISATION_DETAILS.ORG_DETAILS.KVK', fieldMapping, details.kvk));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('kvk', 'ORGANISATION_DETAILS.ORG_DETAILS.KVK', fieldMapping, details.kvk));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('statutoryName');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'statutoryName',
						'ORGANISATION_DETAILS.ORG_DETAILS.STATUTAIRE_NAAM',
						fieldMapping,
						details.statutoryName
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'statutoryName',
						'ORGANISATION_DETAILS.ORG_DETAILS.STATUTAIRE_NAAM',
						fieldMapping,
						details.statutoryName
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('tradeNames');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('tradeNames', '', fieldMapping, details.tradeNames));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('tradeNames', '', fieldMapping, details.tradeNames));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('legalForm');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('legalForm', '', fieldMapping, details.legalForm));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('legalForm', '', fieldMapping, details.legalForm));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('legalEntityIdentifier');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('legalEntityIdentifier', '', fieldMapping, details.legalEntityIdentifier));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('legalEntityIdentifier', '', fieldMapping, details.legalEntityIdentifier));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('relationNumber');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'relationNumber',
						'ORGANISATION_DETAILS.ORG_DETAILS.RELATIENUMMER',
						fieldMapping,
						details.relationNumber
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'relationNumber',
						'ORGANISATION_DETAILS.ORG_DETAILS.RELATIENUMMER',
						fieldMapping,
						details.relationNumber
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('afmId');
		if (fieldMapping != null) {
			// never editable
			attrs.push(this.formAttributeFactory.CreateLabelAttribute('afmId', null!, fieldMapping, details.afmId));
		}

		fieldMapping = fieldMappings.GetFieldMapping('foundationDate');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateDateAttribute('foundationDate', '', fieldMapping, details.foundationDate));
			} else {
				const fieldMappingWithType = { ...fieldMapping, type: 'DateTime' };
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('foundationDate', '', fieldMappingWithType, details.foundationDate));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('statutoryCity');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('statutoryCity', '', fieldMapping, details.statutoryCity));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('statutoryCity', '', fieldMapping, details.statutoryCity));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('statutoryCountry');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateTextAttribute('statutoryCountry', '', fieldMapping, details.statutoryCountry));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('statutoryCountry', '', fieldMapping, details.statutoryCountry));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('classification');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'classification',
						'ORGANISATION_DETAILS.ORG_DETAILS.SOORT_ONDERNEMING',
						fieldMapping,
						details.classification
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'classification',
						'ORGANISATION_DETAILS.ORG_DETAILS.SOORT_ONDERNEMING',
						fieldMapping,
						details.classification
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('governanceModel');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				const values = [
					'Omgekeerd gemengd model (OTB)',
					'Onafhankelijk gemengd model (OTB)',
					'Onafhankelijk model',
					'Paritair gemengd model (OTB)',
					'Paritair model'
				];
				if (!values.includes(details.governanceModel)) {
					values.push(details.governanceModel);
				}
				const options = this.formAttributeFactory.CreateOptionsFromStrings(values);
				attrs.push(
					this.formAttributeFactory.CreateOptionSetAttribute(
						'governanceModel',
						'ORGANISATION_DETAILS.ORG_DETAILS.BESTUURSMODEL',
						details.governanceModel,
						options,
						fieldMapping
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'governanceModel',
						'ORGANISATION_DETAILS.ORG_DETAILS.BESTUURSMODEL',
						fieldMapping,
						details.governanceModel
					)
				);
			}
		}

		return attrs;
	}

	public CreateOrganisationRelationFormAttributes(organisationRelation: IOrganisationRelation, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (organisationRelation == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(organisationRelation.fieldMappings);

		let fieldMapping = fieldMappings.GetFieldMapping('name');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute('name', 'ORGANISATION_DETAILS.ORG_DETAILS.NAME', fieldMapping, organisationRelation.name)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('name', 'ORGANISATION_DETAILS.ORG_DETAILS.NAME', fieldMapping, organisationRelation.name)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('foundedOn');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute(
						'foundedOn',
						'ORGANISATION_DETAILS.ORG_DETAILS.FOUNDED_DATE',
						fieldMapping,
						organisationRelation.foundedOn
					)
				);
			} else {
				console.log('field to label mapping for foundedOn: ', fieldMapping);
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'foundedOn',
						'ORGANISATION_DETAILS.ORG_DETAILS.FOUNDED_DATE',
						fieldMapping,
						organisationRelation.foundedOn
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('relationNumber');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'relationNumber',
						'ORGANISATION_DETAILS.ORG_DETAILS.RELATIENUMMER',
						fieldMapping,
						organisationRelation.relationNumber
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'relationNumber',
						'ORGANISATION_DETAILS.ORG_DETAILS.RELATIENUMMER',
						fieldMapping,
						organisationRelation.relationNumber
					)
				);
			}
		}

		return attrs;
	}

	public CreateOrganisationRelationChangeFormAttributes(organisationRelation: IOrganisationRelation): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (organisationRelation == null) {
			return attrs;
		}

		const mapping = organisationRelation.entityMapping;
		const editMode = mapping != null && (mapping.allowAdd || mapping.allowChange || mapping.allowDelete);

		if (editMode) {
			const fieldMappings = new FieldMappings(organisationRelation.fieldMappings);
			const fieldMapping = fieldMappings.GetFieldMapping('name');

			const values = organisationRelation.relationChangeChoices.map((rcc) => {
				return { label: rcc.name, value: rcc.id };
			});
			const options = this.formAttributeFactory.CreateOptionsFromLabelValuePairs(values);
			const optionSetAttribute = this.formAttributeFactory.CreateOptionSetAttribute(
				'organisationId',
				'ORGANISATION_DETAILS.ORG_DETAILS.NAME',
				organisationRelation.organisationId,
				options,
				fieldMapping
			);
			optionSetAttribute.placeholderTranslationKey = 'ORGANISATION_DETAILS.RELATION.NONE';
			if (organisationRelation.entityMapping == null || organisationRelation.entityMapping.allowDelete != true) {
				optionSetAttribute.isRequired = true;
			}
			attrs.push(optionSetAttribute);
		} else {
			attrs.push(this.formAttributeFactory.CreateLabelAttribute('name', 'ORGANISATION_DETAILS.ORG_DETAILS.NAME', null!, organisationRelation.name));
		}

		return attrs;
	}

	public CreateOrganisationPersonRelationFormAttributes(personRelation: IOrganisationPersonRelation, editMode: boolean): FormAttribute[] {
		const attrs = <FormAttribute[]>[];
		if (personRelation == null) {
			return attrs;
		}

		const fieldMappings = new FieldMappings(personRelation.fieldMappings);

		let fieldMapping: IFieldMapping = fieldMappings.GetFieldMapping('fullname');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute('fullname', 'ORGANISATION_DETAILS.PERSON.FULLNAME', fieldMapping, personRelation.fullname)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('fullname', 'ORGANISATION_DETAILS.PERSON.FULLNAME', fieldMapping, personRelation.fullname)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('surname');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute('surname', 'ORGANISATION_DETAILS.PERSON.LASTNAME', fieldMapping, personRelation.surname)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('surname', 'ORGANISATION_DETAILS.PERSON.LASTNAME', fieldMapping, personRelation.surname)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('firstnames');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'firstnames',
						'ORGANISATION_DETAILS.PERSON.FIRSTNAME',
						fieldMapping,
						personRelation.firstnames
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'firstnames',
						'ORGANISATION_DETAILS.PERSON.FIRSTNAME',
						fieldMapping,
						personRelation.firstnames
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('nameprefix');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'nameprefix',
						'ORGANISATION_DETAILS.PERSON.NAMEPREFIX',
						fieldMapping,
						personRelation.nameprefix
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'nameprefix',
						'ORGANISATION_DETAILS.PERSON.NAMEPREFIX',
						fieldMapping,
						personRelation.nameprefix
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('gender');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				// todo: special gender picker?
				const options = this.formAttributeFactory.CreateOptionsFromStrings(['Male', 'Female']);
				attrs.push(
					this.formAttributeFactory.CreateOptionSetAttribute(
						'gender',
						'ORGANISATION_DETAILS.PERSON.GENDER',
						personRelation.gender,
						options,
						fieldMapping
					)
				);
			} else {
				// value is translateable for gender
				const content =
					personRelation.gender === 'Male'
						? 'ORGANISATION_DETAILS.PERSON.MALE'
						: personRelation.gender === 'Female'
						  ? 'ORGANISATION_DETAILS.PERSON.FEMALE'
						  : null;
				const labelAttr = this.formAttributeFactory.CreateLabelAttribute('gender', 'ORGANISATION_DETAILS.PERSON.GENDER', fieldMapping, content);
				labelAttr.translateContent = true;
				attrs.push(labelAttr);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('dateOfBirth');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute(
						'dateOfBirth',
						'ORGANISATION_DETAILS.PERSON.DATEOFBIRTH',
						fieldMapping,
						personRelation.dateOfBirth
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'dateOfBirth',
						'ORGANISATION_DETAILS.PERSON.DATEOFBIRTH',
						fieldMapping,
						personRelation.dateOfBirth
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('emailAddress');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'emailAddress',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.EMAIL',
						fieldMapping,
						personRelation.emailAddress
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'emailAddress',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.EMAIL',
						fieldMapping,
						personRelation.emailAddress
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('mobilePhone');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'mobilePhone',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.MOBILE',
						fieldMapping,
						personRelation.mobilePhone
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'mobilePhone',
						'ORGANISATION_DETAILS.CONTACT_CHANNELS.MOBILE',
						fieldMapping,
						personRelation.mobilePhone
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('approvalDate');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute(
						'approvalDate',
						'ORGANISATION_DETAILS.RELATION.APPROVALDATE',
						fieldMapping,
						personRelation.approvalDate
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'approvalDate',
						'ORGANISATION_DETAILS.RELATION.APPROVALDATE',
						fieldMapping,
						personRelation.approvalDate
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('startDate');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute(
						'startDate',
						'ORGANISATION_DETAILS.RELATION.STARTDATE',
						fieldMapping,
						personRelation.startDate
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'startDate',
						'ORGANISATION_DETAILS.RELATION.STARTDATE',
						fieldMapping,
						personRelation.startDate
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('term');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(this.formAttributeFactory.CreateDateAttribute('term', 'ORGANISATION_DETAILS.RELATION.TERM', fieldMapping, personRelation.term));
			} else {
				attrs.push(this.formAttributeFactory.CreateLabelAttribute('term', 'ORGANISATION_DETAILS.RELATION.TERM', fieldMapping, personRelation.term));
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('endOfPeriod');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute(
						'endOfPeriod',
						'ORGANISATION_DETAILS.RELATION.ENDOFPERIOD',
						fieldMapping,
						personRelation.endOfPeriod
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'endOfPeriod',
						'ORGANISATION_DETAILS.RELATION.ENDOFPERIOD',
						fieldMapping,
						personRelation.endOfPeriod
					)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('endDate');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateDateAttribute('endDate', 'ORGANISATION_DETAILS.RELATION.ENDDATE', fieldMapping, personRelation.endDate)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute('endDate', 'ORGANISATION_DETAILS.RELATION.ENDDATE', fieldMapping, personRelation.endDate)
				);
			}
		}

		fieldMapping = fieldMappings.GetFieldMapping('articulation');
		if (fieldMapping != null) {
			if (editMode && fieldMapping.isModifiable) {
				attrs.push(
					this.formAttributeFactory.CreateTextAttribute(
						'articulation',
						'ORGANISATION_DETAILS.RELATION.ARTICULATION',
						fieldMapping,
						personRelation.articulation
					)
				);
			} else {
				attrs.push(
					this.formAttributeFactory.CreateLabelAttribute(
						'articulation',
						'ORGANISATION_DETAILS.RELATION.ARTICULATION',
						fieldMapping,
						personRelation.articulation
					)
				);
			}
		}

		return attrs;
	}

	public CreateFormAttributeForDataModelProperty(property: IDataModelProperty, editMode: boolean, formArrayPrefix = '') {
		const attributeName = formArrayPrefix !== '' ? formArrayPrefix + '_' + property.technicalName : property.technicalName;

		if (editMode && property.metaData.isModifiable) {
			if (property.metaData.type === 'String') {
				// if the property value is limited to a set of values, create an optionset attribute
				if (property.metaData.allowedValues != null && property.metaData.allowedValues.length > 0) {
					const options = property.metaData.allowedValues.map((allowedValue) => <Option>{ label: allowedValue, value: allowedValue });
					// ensure current, non null, non empty, value
					if (property.value != null && property.value != '' && !options.some((o) => o.value == property.value)) {
						options.push(<Option>{ label: property.value, value: property.value });
					}

					return this.formAttributeFactory.CreateOptionSetAttribute(
						attributeName,
						null!,
						property.value,
						options,
						property.metaData,
						property.entityType
					);
				}

				return this.formAttributeFactory.CreateTextAttribute(attributeName, null!, property.metaData, property.value);
			} else if (property.metaData.type === 'DateTime') {
				return this.formAttributeFactory.CreateDateAttribute(attributeName, null!, property.metaData, property.value);
			} else if (property.metaData.type === 'OptionSetValue') {
				return this.formAttributeFactory.CreateOptionSetAttribute(
					attributeName,
					null!,
					this.getOptionSetValue(property.value)!,
					null!,
					property.metaData,
					property.entityType
				);
			}
		} else {
			// create conversion function when new flavors are added.
			let displayValue;
			switch (property.metaData.type) {
				case 'DateTime':
					displayValue = property.value;
					break;
				case 'OptionSetValue':
					displayValue = this.getOptionSetDisplayValue(property.value);
					break;
				default:
					displayValue = property.value;
			}

			return this.formAttributeFactory.CreateLabelAttribute(attributeName, null!, property.metaData, displayValue!);
		}
		return new FormAttribute();
	}

	private getOptionSetValue(value: string) {
		if (!value) return '';

		const result = value.split('|', 2);

		return result[0];
	}

	private getOptionSetDisplayValue(value: string) {
		if (!value) return '';

		const result = value.split('|', 2);

		if (result.length === 2) {
			return result[1];
		}

		return result[0];
	}

	private convertPropertiesToAttributes(dataModelproperties: IDataModelProperty[], editMode: boolean): FormAttribute[] {
		if (dataModelproperties === null) {
			return [];
		}

		const formAttributes: FormAttribute[] = [];

		dataModelproperties.forEach((property) => {
			formAttributes.push(this.CreateFormAttributeForDataModelProperty(property, editMode)!);
		});

		return formAttributes;
	}

	public CreateFormAttributesForDataModel(dataModel: IDataModel, editMode: boolean): FormAttribute[] {
		if (dataModel == null || dataModel.properties == null) {
			return [];
		}

		return this.convertPropertiesToAttributes(dataModel.properties, editMode);
	}

	public CreateFormAttributesForDataModels(dataModels: IDataModel[], editMode: boolean): FormAttribute[] {
		if (dataModels === null) {
			return [];
		}

		let formAttributes: FormAttribute[] = [];

		dataModels.forEach((dataModel) => {
			formAttributes = formAttributes.concat(this.CreateFormAttributesForDataModel(dataModel, editMode));
		});

		return formAttributes;
	}

	public CreateFeedbackFormAttributes(fieldMappings: IFieldMapping[]): FormAttribute[] {
		const fm = new FieldMappings(fieldMappings);

		const subjectChoices: string[] = [
			'ORGANISATION_DETAILS.FEEDBACK.SUBJECT_CHOICE_1',
			'ORGANISATION_DETAILS.FEEDBACK.SUBJECT_CHOICE_2',
			'ORGANISATION_DETAILS.FEEDBACK.SUBJECT_CHOICE_3',
			'ORGANISATION_DETAILS.FEEDBACK.SUBJECT_CHOICE_4'
		];

		const options = this.formAttributeFactory.CreateOptionsFromStrings(subjectChoices);

		const subjectAttr: OptionSetAttribute = this.formAttributeFactory.CreateOptionSetAttribute(
			'subject',
			'ORGANISATION_DETAILS.FEEDBACK.SUBJECT',
			null!,
			options,
			fm.GetFieldMapping('subject')
		);
		const bodyAttr: TextAttribute = this.formAttributeFactory.CreateTextAttribute(
			'body',
			'ORGANISATION_DETAILS.FEEDBACK.BODY',
			fm.GetFieldMapping('body'),
			null!
		);

		// feedback screen supports wide layout, as there is no extra menu on the left
		subjectAttr.layoutSettings!.supportWideLayout = true;
		bodyAttr.layoutSettings!.supportWideLayout = true;

		bodyAttr.isMultiLine = true;
		bodyAttr.rows = 16;

		return [subjectAttr, bodyAttr];
	}
}
