import { Component, Inject } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";
import { ButtonAttribute, FormsButtonComponent, NotificationBoxComponent, PermissionType } from "@dnb/supervision-core/src/lib/dlt-ui";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ButtonSize, HideIfUnauthorizedDirective, HttpServiceError } from "src/app/shared";
import { DocumentDeleteModalComponentContext, IDocument } from "../../models";
import { DocumentDeleteButtonComponent } from "../document-delete-button/document-delete-button.component";
import { IDocumentService } from '../../interfaces';
import { DOCUMENTS_SERVICE } from '../../dependency-injection';

@Component({
  selector: 'app-document-delete-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsButtonComponent,
    NotificationBoxComponent,
    HideIfUnauthorizedDirective,
    MatDialogModule,
    DocumentDeleteButtonComponent,
    JsonPipe],
  templateUrl: './document-delete-modal.component.html',
  styleUrls: ['./document-delete-modal.component.scss']
})
export class DocumentDeleteModalComponent {

  private readonly destroy$ = new Subject<void>();

  PermissionType = PermissionType;
  
  error: string = '';
  size: ButtonSize = ButtonSize.Large;
  document: IDocument;

  constructor(
    private dialogRef: MatDialogRef<DocumentDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) private dataContext: DocumentDeleteModalComponentContext,
    @Inject(DOCUMENTS_SERVICE) private documentService: IDocumentService,
    ){}

  ngOnInit(): void {
    this.document = this.dataContext.document;
  }

  onCancel(): void {
		this.dialogRef.close(false);
	}

	onSave(): void {
    this.documentService.delete(this.document.fileId)
      .pipe()
      .subscribe({
        next: (response) => {
          if (response.fileId === this.document.fileId){
            this.dialogRef.close(true);
          }
        },
        error: (error: Error) => {
          this.error = error instanceof HttpServiceError ? error.translationToken: error.message;
          }
        });
	}

  get cancelButtonAttribute(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'medium',
			iconLeft: 'fa fa-times',
			showIconLeft: true,
			text: 'FORM.CANCEL',
		};
	}
}
