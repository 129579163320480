import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, interval, map, of, takeUntil } from 'rxjs';
import { SignalRHubConnectionState } from 'src/app/core';
import { IDocumentsHubService } from '../interfaces';
import { FileReadyForDownloadEvent, FileAvailableForDownloadEvent } from '../events';

@Injectable()
export class DocumentsHubMockedService implements IDocumentsHubService, OnDestroy {
	private readonly destroy$ = new Subject<void>();

	private stateSubject = new Subject<SignalRHubConnectionState | null>();
	private errorSubject = new Subject<Error | null>();
	private fileUploadStatusSubject = new Subject<FileAvailableForDownloadEvent>();
	private downloadReadySubject = new Subject<FileReadyForDownloadEvent>();

	state$: Observable<SignalRHubConnectionState | null>;
	error$: Observable<Error | null>;
	fileAvailableForDownload$: Observable<FileAvailableForDownloadEvent>;
	fileReadyForDownload$: Observable<FileReadyForDownloadEvent>;

	constructor() {
		this.state$ = this.stateSubject.asObservable();
		this.error$ = this.errorSubject.asObservable();
		this.fileAvailableForDownload$ = this.fileUploadStatusSubject.asObservable();
		this.fileReadyForDownload$ = this.downloadReadySubject.asObservable();

		this.similateFileReadyForDownloadEvent();
		this.similateFileUpdateStatusEvent();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	get connected(): boolean {
		return true;
	}

	start(): Observable<void> {
		this.stateSubject.next('connected');
		return of();
	}

	private similateFileReadyForDownloadEvent(): void {
		interval(5000)
			.pipe(
				takeUntil(this.destroy$),
				map((): FileReadyForDownloadEvent => ({ fileId: 'ENCRYPTED-FILE-ID-001', success: true }))
			)
			.subscribe((message) => {
				this.downloadReadySubject.next(message);
			});
	}

	private similateFileUpdateStatusEvent(): void {
		interval(5000)
			.pipe(
				takeUntil(this.destroy$),
				map((): FileAvailableForDownloadEvent => ({ fileId: 'ENCRYPTED-FILE-ID-001', externalFileName: 'test.pdf' }))
			)
			.subscribe((message) => {
				this.fileUploadStatusSubject.next(message);
			});
	}
}
