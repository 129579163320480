<form *ngIf="formGroup" [formGroup]="formGroup">
	<mat-form-field [hideRequiredMarker]="!label" [floatLabel]="'always'" [appearance]="'outline'">
		<!-- label -->
		<mat-label *ngIf="label">
			{{ label | translate }}
		</mat-label>

		<!-- input -->
		<input matInput [matDatepicker]="picker" [formControl]="control" [placeholder]="placeHolder" (dateChange)="onDateChange($event)" />
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>

		<!-- error -->
		<mat-error *ngIf="control.hasError('required')">
			{{ errorRequired | translate }}
		</mat-error>
	</mat-form-field>
</form>
