import { Component } from '@angular/core';
import { FormAttribute } from '@dnb/supervision-core';
import { IOrganisationObject } from 'src/app/shared/models/organisation-object.model';

@Component({ template: '' })
export abstract class ContactChannelBaseComponent {
	private _formAttributes: FormAttribute[];

	protected abstract createFormAttributes(): FormAttribute[];
	protected abstract getOrganisationObjectAfterInit(): IOrganisationObject;

	hasHelpInformation: boolean = false;
	showHelpInformation: boolean = false;
	helpInformation!: string;

	ngOnInit(): void {
		const obj = this.getOrganisationObjectAfterInit();
		this.hasHelpInformation = obj?.entityMapping?.helpTextResourceKey != null;
		this.helpInformation = this.hasHelpInformation ? obj.entityMapping.helpTextResourceKey : null!;
	}

	get FormAttributes(): FormAttribute[] {
		if (this._formAttributes == null) {
			this._formAttributes = this.createFormAttributes();
		}
		return this._formAttributes;
	}

	onToggleHelpInformation() {
		this.showHelpInformation = !this.showHelpInformation;
	}

	onCloseHelpInformation() {
		this.showHelpInformation = false;
	}

	refreshFormAttributes() {
		this._formAttributes = [];
	}
}
