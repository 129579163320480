import { Component, OnInit } from '@angular/core';
import { AuthBroadcastService } from './auth-broadcast.service';

@Component({
	selector: 'app-sign-out',
	template: ''
})
export class SignOutComponent implements OnInit {
	constructor(private broadcastService: AuthBroadcastService) {}

	ngOnInit(): void {
		// Implement local session cleanup logic here
		sessionStorage.clear();

		// Broadcast single signout to other app instances
		this.broadcastService.publish({
			type: 'single_signout',
			payload: null
		});
	}
}
