<form [formGroup]="form" (ngSubmit)="submit()">
	<!-- file -->
	<div class="form-element">
		<mdb-file-upload [ngClass]="{'submitted': submitted}" formControlName="file" [uploadSession]="uploadSession" [variant]="uploadVariant"/>
	</div>

	<!-- documentType -->
	<div class="form-element">
		<app-form-control-select
			controlName="documentType"
			label="ORGANISATION_DETAILS.DOCUMENT.DOCUMENTTYPE"
			[required]="true"
			[options]="documentTypeOptions" />
	</div>

	<!-- year -->
	<div class="form-element" *ngIf="required.year">
		<app-form-control-select controlName="year" label="ORGANISATION_DETAILS.DOCUMENT.YEAR" [required]="true" [options]="yearOptions" />
	</div>

	<!-- validFrom -->
	<div class="form-element" *ngIf="required.validFrom">
		<app-form-control-date controlName="validFrom" label="ORGANISATION_DETAILS.DOCUMENT.VALIDFROM" [required]="true" />
	</div>

	<!-- lastDayBookYear -->
	<div class="form-element" *ngIf="required.lastDayBookYear">
		<app-form-control-date controlName="lastDayBookYear" label="ORGANISATION_DETAILS.DOCUMENT.LAST_DAY_BOOKYEAR" [required]="true" />
	</div>

	<!-- regarding -->
	<div class="form-element" *ngIf="required.regarding">
		<app-form-control-text
			controlName="regarding"
			[label]="getRegardingLabelForDocumentType(this.form.get('documentType').value)"
			[required]="true" />
	</div>
</form>

<!-- debug info -->
<ng-container *ngIf="false">
	<p>Form Values: {{ form.value | json }}</p>
	<p>Form Status: {{ form.status }}</p>
	<p>Required Form Values: {{ required | json }}</p>
	<p>File upload session: {{ uploadSession | json }}</p>
</ng-container>
