import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IMonitoringService } from 'src/app/core/interfaces/monitoring-service';
import { environment } from 'src/environments/environment';
import { DurationMetric } from '../models/duration-metric.models';

@Injectable({
	providedIn: 'root'
})
export class MonitoringService implements IMonitoringService {
	appInsights: ApplicationInsights;

	constructor() {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.appInsights.instrumentationKey,
				enableAutoRouteTracking: true
			}
		});
		this.appInsights.loadAppInsights();
	}

	logEvent(name: string, properties?: { [name: string]: string }, measurements?: unknown): void {
		console.group('MonitoringService::logEvent');
		console.log('name: ', name);
		console.log('properties: ', properties);
		console.log('measurements: ', measurements);
		console.groupEnd();

		this.appInsights.trackEvent({ name: name }, properties);
	}

	startDurationMetric(name: string): DurationMetric {
		console.group('MonitoringService::startDurationMetric');
		console.log('name: ', name);
		console.groupEnd();

		return new DurationMetric(name);
	}

	stopAndTrackDurationMetric(durationMetric: DurationMetric): void {
		console.group('MonitoringService::stopAndTrackDurationMetric');
		console.log('durationMetric: ', durationMetric);
		console.groupEnd();
	}

	logException(ex: Error, properties?: { [name: string]: string }): void {
		console.group('MonitoringService::logException');
		console.error('ex: ', ex);
		console.error('properties: ', properties);
		console.groupEnd();

		this.appInsights.trackException({ exception: ex }, properties);
	}
}
