import { FormControl } from '@angular/forms';
import { IDocumentType } from './document-type.models';
import * as moment from 'moment';

export interface UploadForm {
	file: FormControl<string[]>;
	documentType: FormControl<string | null>;
	year: FormControl<string | null>;
	validFrom: FormControl<moment.Moment | null>;
	lastDayBookYear: FormControl<moment.Moment | null>;
	regarding: FormControl<string | null>;
}

export interface UploadFormValues {
	file: string[];
	documentType: string | null;
	year: string | null;
	validFrom: moment.Moment | null;
	lastDayBookYear: moment.Moment | null;
	regarding: string | null;
}

export const initialUploadFormValues: UploadFormValues = {
	file: [],
	documentType: null,
	year: null,
	validFrom: null,
	lastDayBookYear: null,
	regarding: null
};

export type UploadFormValuesRequired = {
	[K in keyof Omit<UploadFormValues, 'file' | 'documentType'>]: boolean;
};

export const initialUploadFormValuesRequired: UploadFormValuesRequired = {
	year: true,
	validFrom: true,
	lastDayBookYear: true,
	regarding: true
};

export function mapToRequiredFormValues(documentType: IDocumentType): UploadFormValuesRequired {
	return {
		year: documentType.yearRequired,
		validFrom: documentType.validFromRequired,
		lastDayBookYear: documentType.lastDayBookYearRequired,
		regarding: documentType.regardingRequired
	};
}

export function mapUploadFormValues(formValues: Partial<UploadFormValues>, required: UploadFormValuesRequired) {
	return {
		fileId: formValues.file[0],
		documentType: formValues.documentType,
		lastDayBookYear: required.lastDayBookYear ? formValues.lastDayBookYear.toString() : null,
		year: required.year ? parseInt(formValues.year, 10) : null,
		validFrom: required.validFrom ? formValues.validFrom.toString() : null,
		regarding: required.validFrom ? formValues.regarding : null
	};
}
