import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, timer } from 'rxjs';
import { IHttpError } from '../../shared/models/http-error';
import { IHttpClient } from './ihttp-client';

@Injectable({
	providedIn: 'root'
})
export class CustomHttpClient implements IHttpClient {
	constructor(private httpClient: HttpClient) {}

	withRetry<TResult>(call$: Observable<TResult>): Observable<TResult> {
		return call$.pipe(
			retry({
				count: 9,
				delay: (error, count) => {
					// with error as IHttpError, check for retry possible
					const blockRetry = error && (error as IHttpError).canRetry !== true;
					if (blockRetry || count > 8) {
						// simply throw the error again, so it can be handled by the top level subscriber
						throw error;
					}
					return timer(Math.min(60000, (2 ^ count) * 500));
				}
			})
		);
	}

	post<TResult>(url: string, payload: any): Observable<TResult> {
		return this.withRetry(this.httpClient.post<TResult>(url, payload));
	}
	put<TResult>(url: string, payload: any): Observable<TResult> {
		return this.withRetry(this.httpClient.put<TResult>(url, payload));
	}
	get<TResult>(url: string): Observable<TResult> {
		return this.withRetry(this.httpClient.get<TResult>(url));
	}
	getWithParams<TResult>(url: string, params: HttpParams): Observable<TResult> {
		return this.withRetry(this.httpClient.get<TResult>(url, { params }));
	}
	getFile(url: string): Observable<ArrayBuffer> {
		return this.httpClient.get(url, { responseType: 'arraybuffer' });
	}
	delete<TResult>(url: string): Observable<TResult> {
		return this.withRetry(this.httpClient.delete<TResult>(url));
	}
}
