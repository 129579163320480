import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { Option } from '../option.model';

export class OptionSetAttribute extends FormAttribute {
	placeholderTranslationKey!: string;
	options!: Option[];
	isRadio!: boolean;
	/** additional property, not part of the form specification */
	translateOptions!: boolean;
}
