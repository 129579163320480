import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAttribute, FormsButtonComponent } from '@dnb/supervision-core';

@Component({
	selector: 'app-document-upload-button',
	standalone: true,
	imports: [CommonModule, FormsButtonComponent],
	templateUrl: './document-upload-button.component.html'
})
export class DocumentUploadButtonComponent {
	@Input() documentType!: string;
	@Input() firstUpload!: boolean;

	@Output() clicked = new EventEmitter<string>();

	public get UploadButton(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'extra-small',
			iconLeft: 'fa fa-upload',
			showIconLeft: true,
			tooltip: this.firstUpload ? 'ORGANISATION_DETAILS.DOCUMENTS.UPLOAD' : 'ORGANISATION_DETAILS.DOCUMENTS.UPLOAD_NEW'
		};
	}

	onClick(): void {
		this.clicked.emit(this.documentType);
	}
}
