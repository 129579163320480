import { Pipe, PipeTransform } from '@angular/core';
import { IDocument } from '../models';

@Pipe({
	name: 'documentsFilter',
	standalone: true
})
export class DocumentsFilterPipe implements PipeTransform {
	transform(documents: IDocument[], documentType: string | null): IDocument[] {
		if (!documents) {
			return [];
		}

		if (!documentType) {
			return documents;
		}

		return documents.filter((document) => document.documentType == documentType);
	}
}
