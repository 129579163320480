import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAttribute, FormAttributeComponent } from '@dnb/supervision-core/src/lib/dlt-forms';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-organisation-entity-edit-properties',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, FormAttributeComponent],
	templateUrl: './organisation-entity-edit-properties.component.html'
})
export class OrganisationEntityEditPropertiesComponent {
	@Input() formAttributes!: FormAttribute[];
	@Input() formGroup!: UntypedFormGroup;
}
