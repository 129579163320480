import { IOption } from './option.models';

export type LastPeriodShownType = 'Unknown' | 'LastDocument' | 'Last5Documents' | 'LastYear' | 'All';

export interface IDocumentType {
	id: string;
	lastDayBookYearRequired: boolean;
	lastPeriodShown: LastPeriodShownType;
	name: string;
	regardingRequired: boolean;
	uploadEnabled: boolean;
	validFromRequired: boolean;
	yearRequired: boolean;
	supervisionExecutionLabel: string;
}

export interface IDocumentTypeMap {
	[key: string]: IDocumentType;
}

export function mapToDocumentTypes(documentTypes: IDocumentType[], initialValue: IDocumentTypeMap = {}): IDocumentTypeMap {
	return documentTypes.reduce((acc, documentType) => {
		acc[documentType.name] = documentType;
		return acc;
	}, initialValue);
}

export function mapToDocumentOptions(documentTypes: IDocumentType[]): IOption[] {
	return documentTypes.map((documentType) => ({ value: documentType.name, viewValue: documentType.name }));
}
