import { SignalRHubConnection } from '../service/signalr-hub-connection';
import { ISignalRHubConnectionFactory } from './signalr-hub-connection-factory.interface';

export class SignalRHubConnectionFactory
  implements ISignalRHubConnectionFactory
{
  constructor(
    private connectionFactory: (url: string) => signalR.HubConnection
  ) {}

  createConnection(hubName: string, url: string): SignalRHubConnection {
    return new SignalRHubConnection(hubName, url, this.connectionFactory);
  }
}
