<h3 mat-dialog-title>{{ fileName }}</h3>
<div mat-dialog-content >
    <ng-container *ngIf="connected; else notConnectedTemplate">
        <div class="progress" *ngIf="!error; else errorTemplate">        
            <div class="progress__status" [ngSwitch]="downloadStatus">
                <ng-container *ngSwitchCase="'preparing'">
                    {{ 'ORGANISATION_DETAILS.DOCUMENT_DOWNLOAD_MODAL.PREPARE_DOWNLOADING' | translate}}
                </ng-container>
                <ng-container *ngSwitchCase="'downloading'">
                    {{ 'ORGANISATION_DETAILS.DOCUMENT_DOWNLOAD_MODAL.DOWNLOADING' | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="'completed'">
                    {{ 'ORGANISATION_DETAILS.DOCUMENT_DOWNLOAD_MODAL.DOWNLOADED_COMPLETED' | translate }}
                </ng-container>
            </div>
            <mat-progress-bar [mode]="downloadStatus === 'preparing' ? 'buffer' : 'determinate'" [value]="progress"/>
        </div>
    </ng-container>
</div>

<div mat-dialog-actions align="end">
    <dlt-forms-button (clicked)="onClose()" [buttonAttribute]="cancelButtonAttribute" />
</div>

<ng-template #errorTemplate>
    <dlt-notification-box [text]="error"></dlt-notification-box>
</ng-template>

<ng-template #notConnectedTemplate>
    <dlt-notification-box [text]="'ORGANISATION_DETAILS.DOCUMENT_DOWNLOAD_MODAL.UNABLE_TO_DOWNLOAD'"></dlt-notification-box>
</ng-template>