<div [formGroup]="formGroup">
	<div class="row">
		<div class="col-xs-12">
			<input
				type="checkbox"
				(change)="onCheckedChanged($event)"
				[checked]="contactChannel.showAddress"
				class="input-cc"
				*ngIf="contactChannel.isOptional" />
			<span *ngIf="contactChannel.entityMapping.typeLabelResourceKey" class="contact-channel-header">{{
				contactChannel.entityMapping.typeLabelResourceKey | translate
			}}</span>
			<dlt-info-button *ngIf="hasHelpInformation" (toggle)="onToggleHelpInformation()"></dlt-info-button>
		</div>
	</div>
	<dlt-info-box
		*ngIf="showHelpInformation"
		[content]="helpInformation"
		[translateContent]="true"
		(closeBox)="onCloseHelpInformation()"></dlt-info-box>
	<ng-container *ngIf="contactChannel.showAddress">
		<app-organisation-entity-edit-properties [formAttributes]="FormAttributes" [formGroup]="formGroup"> </app-organisation-entity-edit-properties>
	</ng-container>
</div>
