import { Directive, ElementRef, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionType } from '@dnb/supervision-core/src/lib/dlt-ui';
import { IUserPermissionsService, USER_PERMISSIONS_SERVICE } from 'src/app/shared';

@Directive({
	selector: '[hideIfUnauthorized]',
	standalone: true
})
export class HideIfUnauthorizedDirective {
	private permission: PermissionType | undefined;

	constructor(
		private elementRef: ElementRef,
		private templateRef: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef,
		@Inject(USER_PERMISSIONS_SERVICE) private userPermissionsService: IUserPermissionsService
	) {}

	@Input('hideIfUnauthorized')
	public set feature(permission: PermissionType) {
		this.permission = permission;
		this.updateView();
	}

	updateView() {
		if (!this.permission) {
			this.viewContainer.clear();
			return;
		}

		if (this.userPermissionsService.hasPermission(this.permission)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
