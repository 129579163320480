import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { IAddress } from 'src/app/shared/models/contact-channel.model';
import { IOrganisationObject } from 'src/app/shared/models/organisation-object.model';
import { OrganisationEntityFormFactory, FieldMappings } from 'src/app/shared/services/factories/organisation-entity-form.factory';
import { ContactChannelEditBaseComponent } from '../contact-channel-edit-base.component';
import { InfoBoxComponent, InfoButtonComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationEntityEditPropertiesComponent } from '../../organisation-entity-edit-properties/organisation-entity-edit-properties.component';

@Component({
	selector: 'app-address-contact-channel-edit',
	standalone: true,
	imports: [
		CommonModule,
		InfoBoxComponent,
		InfoButtonComponent,
		ReactiveFormsModule,
		FormsModule,
		TranslateModule,
		OrganisationEntityEditPropertiesComponent
	],
	templateUrl: './address-contact-channel-edit.component.html',
	styleUrls: ['./address-contact-channel-edit.component.scss']
})
export class AddressContactChannelEditComponent extends ContactChannelEditBaseComponent {
	@Input() contactChannel!: IAddress;
	@Output() includeAddressCheckedChanged = new EventEmitter<{ formGroup: UntypedFormGroup; address: IAddress; checked: boolean }>();

	@Output() usePoBoxNumberChanged = new EventEmitter<{ formGroup: UntypedFormGroup; address: IAddress; value: boolean }>();

	showPoBoxNumber!: boolean;

	constructor(private formFactory: OrganisationEntityFormFactory) {
		super();
	}

	get poBoxNumberEditable(): boolean {
		const fieldMappings = new FieldMappings(this.contactChannel.fieldMappings);
		const mapping = fieldMappings.GetFieldMapping('postOfficeBox');

		if (mapping) {
			return mapping.isModifiable;
		}

		return false;
	}

	public onCheckedChanged($event: any) {
		const obj = {
			formGroup: this.formGroup,
			address: this.contactChannel,
			checked: $event.target.checked
		};

		this.includeAddressCheckedChanged.emit(obj);
	}

	public onUsePoBoxNumberChanged(args: any) {
		const obj = {
			formGroup: this.formGroup,
			address: this.contactChannel,
			value: args
		};
		this.usePoBoxNumberChanged.emit(obj);
		this.refreshFormAttributes();
	}

	protected getOrganisationObjectAfterInit(): IOrganisationObject {
		// only trigger event if the po box is editable
		if (this.poBoxNumberEditable) {
			this.onUsePoBoxNumberChanged(this.contactChannel.isPostBox);
		}

		return this.contactChannel;
	}

	protected createFormAttributes(): FormAttribute[] {
		return this.formFactory.CreatePostalAddressFormAttributes(this.contactChannel, true);
	}
}
