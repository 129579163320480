import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { SignalRHubConnectionState, ISignalRHubConnection, SIGNALR_HUB_CONNECTION_FACTORY, ISignalRHubConnectionFactory } from '../../core/signalr';
import { IDocumentsHubService } from '../interfaces';
import { FileAvailableForDownloadEvent, FileReadyForDownloadEvent } from '../events';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentsHubService implements IDocumentsHubService, OnDestroy {
	private readonly destroy$ = new Subject<void>();
	private readonly connection: ISignalRHubConnection;
	private readonly stateSubject = new BehaviorSubject<SignalRHubConnectionState | null>(null);
	private readonly errorSubject = new BehaviorSubject<Error | null>(null);

	state$: Observable<SignalRHubConnectionState | null>;
	error$: Observable<Error | null>;
	fileAvailableForDownload$: Observable<FileAvailableForDownloadEvent>;
	fileReadyForDownload$: Observable<FileReadyForDownloadEvent>;

	constructor(
		@Inject(SIGNALR_HUB_CONNECTION_FACTORY)
		connectionFactory: ISignalRHubConnectionFactory
	) {
		this.connection = connectionFactory.createConnection('documents', `${environment.apiUrl}/v1/organisationdetails/documents`);

		this.connection.error$.pipe(takeUntil(this.destroy$)).subscribe(this.errorSubject);
		this.connection.state$.pipe(takeUntil(this.destroy$)).subscribe(this.stateSubject);

		this.error$ = this.errorSubject.asObservable();
		this.state$ = this.stateSubject.asObservable();
		this.fileAvailableForDownload$ = this.connection.on<FileAvailableForDownloadEvent>('SignalRFileAvailableForDownload');
		this.fileReadyForDownload$ = this.connection.on<FileReadyForDownloadEvent>('SignalRFileDownloadReady');
	}

	ngOnDestroy(): void {
		this.connection.stop();
		this.destroy$.next();
		this.destroy$.complete();
	}

	get connected(): boolean {
		const state = this.stateSubject.getValue();
		const error = this.errorSubject.getValue();

		return state === 'connected' && !error;
	}

	start(): Observable<void> {
		return this.connection.start();
	}
}
