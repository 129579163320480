import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataEventBrokerService } from '@dnb/supervision-core';
import { IOrganisationObject } from '../models';
import { OrganisationDetailsContentComponent } from './01-organisation-details-content.component';
import { Subject, takeUntil } from 'rxjs';

@Component({ template: '' })
export class OrganisationRelationDetailsContentComponent extends OrganisationDetailsContentComponent implements OnInit, OnDestroy {
	private readonly baseDestroy$ = new Subject<void>();

	protected loadedFromUrl!: string;
	protected relationGroupId!: number;

	hasHelpInformation!: boolean;
	showHelpInformation!: boolean;
	helpInformation!: string;

	constructor(
		eventBroker: DataEventBrokerService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute
	) {
		super(eventBroker);
	}

	ngOnInit() {
		this.activatedRoute.params.pipe(takeUntil(this.baseDestroy$)).subscribe((params) => {
			this.loadFromParams(this.router.url, params);
		});
	}

	ngOnDestroy(): void {
		this.baseDestroy$.next();
		this.baseDestroy$.complete();
	}

	onToggleHelpInformation() {
		this.showHelpInformation = !this.showHelpInformation;
	}

	protected setupHelp(organisationObject: IOrganisationObject): void {
		this.hasHelpInformation = organisationObject?.entityMapping?.helpTextResourceKey != null;
		this.helpInformation = this.hasHelpInformation ? organisationObject.entityMapping.helpTextResourceKey : null!;
	}

	protected loadFromParams(url: string, params: { [key: string]: any }): void {
		this.loadedFromUrl = url;
		this.relationGroupId = params['relationGroupId'];
	}
}
