import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { MonitoringService } from '../services';
import { TranslationResourceConfig } from '../models';

export class CustomTranslateLoader implements TranslateLoader {
	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService,
		private translationResourceConfigs: TranslationResourceConfig[]
	) {}

	getTranslation(lang: string): Observable<any> {
		
		const translationObservables$ = this.translationResourceConfigs.map((config) => {
			const url = `${config.prefix}/${lang}${config.suffix}`;
			return this.httpClient.get(url).pipe(catchError(this.handleHttpError()));
		});

		return forkJoin(translationObservables$).pipe(map((translations) => this.mergeTranslations(translations)));
	}

	private mergeTranslations(translations: any[]): any {
		return Object.assign({}, ...translations);
	}

	private handleHttpError(): (error: HttpErrorResponse) => Observable<object> {
		return (error: HttpErrorResponse) => {
			this.monitoringService.logException(error);
			return of({});
		};
	}
}
