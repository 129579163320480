<h3 mat-dialog-title>{{ 'ORGANISATION_DETAILS.DOCUMENT_UPLOAD_MODAL.TITLE' | translate }}</h3>

<div mat-dialog-content>
	<ng-container *ngIf="!loading; else loadingTemplate">
		<ng-container *ngIf="!loadError; else loadErrorTemplate">
			<!-- processing-indication -->
			<ng-container *ngIf="submitting">
				<i class="fa fa-spin fa-spinner"></i>&nbsp;{{ 'ORGANISATION_DETAILS.DOCUMENT_UPLOAD_MODAL.DOCUMENT_BEING_PROCESSED' | translate }}
			</ng-container>

			<!-- form -->
			<app-document-upload-form
				[values]="formValues"
				[required]="formValuesRequired"
				[disabled]="submitting"
				[uploadSession]="uploadSession"
				[documentTypeOptions]="documentTypeOptions"
				[yearOptions]="yearOptions"
				(valueChange)="onValueChange($event)"
				(onSubmit)="onSubmit($event)" />

			<!-- document-info -->
			<div
				*ngIf="showLastDocumentInfo(this.formValues.documentType)"
				[innerHTML]="'ORGANISATION_DETAILS.DOCUMENT_UPLOAD_MODAL.LAST_DOCUMENT_INFO' | translate"></div>

			<dlt-error-box *ngIf="submitError" [error]="submitError" />
		</ng-container>
	</ng-container>
</div>

<div mat-dialog-actions align="end">
	<dlt-forms-button (clicked)="onCancel()" [buttonAttribute]="cancelButtonAttribute" />

	<ng-container *hideIfUnauthorized="PermissionType.OrganisationContribute">
		<dlt-forms-button (clicked)="onSave()" [buttonAttribute]="saveButtonAttribute" />
	</ng-container>
</div>

<ng-template #loadingTemplate> <i class="fa fa-spin fa-spinner"></i> {{ 'LOADING_DATA' | translate }} </ng-template>

<ng-template #loadErrorTemplate>
	<dlt-notification-box *ngIf="loadError" [text]="loadError" />
</ng-template>
