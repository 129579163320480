import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IMonitoringService } from 'src/app/shared';
import { HttpErrorModel } from './http-error-model.models';
import { getTranslationTokenFromHttpErrorCode } from './http-error-code.models';
import { getTranslationTokenFromHttpStatusCode } from './http-status-code.models';
import { HttpServiceError } from './http-service-error.models';

export const httpHandleError = (monitoringService: IMonitoringService): ((error: HttpErrorResponse) => Observable<never>) => {
	return (error: HttpErrorResponse): Observable<never> => {
		monitoringService.logException(error);

		const isHttpErrorModel = (obj: unknown): obj is HttpErrorModel => {
			return typeof obj === 'object' && obj !== null && 'errorCode' in obj && 'message' in obj;
		};

		const translationToken = isHttpErrorModel(error.error)
			? getTranslationTokenFromHttpErrorCode(error.error.errorCode)
			: getTranslationTokenFromHttpStatusCode(error.status);

		return throwError(() => new HttpServiceError(error, translationToken));
	};
};
