import { Injectable, OnDestroy, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MijnDNBLanguageService } from '@dnb/mijndnb-ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LanguageService as LibraryLanguageService } from '@dnb/supervision-core/src/lib/dlt-forms';
import { MonitoringService } from './shared';

@Injectable({
	providedIn: 'root'
})
export class LanguageService implements OnDestroy {
	private readonly destroy$ = new Subject<void>();
	private readonly languages = ['nl', 'en'];

	currentLang: Signal<string>;

	constructor(
		private readonly translateService: TranslateService,
		private readonly libraryLanguageService: LibraryLanguageService,
		private readonly mijndnbLanguageService: MijnDNBLanguageService,
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {
		this.mijndnbLanguageService
			.onLanguageChanged()
			.pipe(takeUntil(this.destroy$))
			.subscribe((lang) => {
				this.translateService.addLangs(this.languages);
				this.translateService.use(lang);
				this.libraryLanguageService.switchLanguage(lang);
			});

		this.currentLang = toSignal(this.mijndnbLanguageService.onLanguageChanged());
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initialize(): Observable<any> {
		// setup translate service by adding languages and set default language as fallback
		this.translateService.addLangs(this.languages);
		return this.translateService.use(this.mijndnbLanguageService.getPreferredLanguage());
	}

	get getFormat() {
		if (this.currentLang() === 'nl') {
			return 'DD-MM-YYYY';
		} else {
			return 'DD/MM/YYYY';
		}
	}

	loadTranslations(lang: string) {
		this.getTranslations(lang).subscribe((t) => {
			this.translateService.setTranslation(lang, t, true);
		});
	}

	getTranslations(lang: string): Observable<any> {
		const url = `${environment.apiUrl}/v1/organisationdetails/resources/${lang}`;
		return this.httpClient.get(url).pipe(catchError(this.handleHttpError()));
	}

	private handleHttpError(): (error: HttpErrorResponse) => Observable<object> {
		return (error: HttpErrorResponse) => {
			this.monitoringService.logException(error);
			return of({});
		};
	}
}
