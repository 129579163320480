import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataEventBrokerService } from '@dnb/supervision-core';
import { OrganisationDetailsContentEditComponent } from './02-organisation-details-content-edit.component';
import { Subject, takeUntil } from 'rxjs';

@Component({ template: '' })
export abstract class OrganisationRelationDetailsContentEditComponent extends OrganisationDetailsContentEditComponent implements OnInit, OnDestroy {
	private readonly baseDestroy$ = new Subject<void>();

	protected relationGroupId: string;

	constructor(
		eventBroker: DataEventBrokerService,
		router: Router,
		activatedRoute: ActivatedRoute,
		formBuilder: UntypedFormBuilder,
		translate: TranslateService
	) {
		super(eventBroker, router, activatedRoute, formBuilder, translate);
	}

	ngOnInit() {
		this.activatedRoute.params.pipe(takeUntil(this.baseDestroy$)).subscribe((params) => {
			this.relationGroupId = params['relationGroupId'];
			this.LoadFromParams(this.router.url, params);
		});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.baseDestroy$.next();
		this.baseDestroy$.complete();
	}

	onSubmit() {
		this.eventBroker.notifySaveStart();

		this.Save().then(
			() => {
				this.eventBroker.notifySaveEnd(true);
				this.navigateAfterSave();
			},
			() => {
				this.eventBroker.notifySaveEnd(false);
			}
		);
	}

	onCancelAndClose() {
		this.navigateAfterCancel();
	}

	protected abstract LoadFromParams(url: string, params: { [key: string]: any }): void;
	protected abstract Save(): Promise<void>;

	private navigateToOverview(): void {
		this.router.navigate(['organisation', 'details', 'rel', this.relationGroupId]);
	}

	protected navigateAfterSave(): void {
		this.navigateToOverview();
	}

	protected navigateAfterCancel(): void {
		this.navigateToOverview();
	}
}
