<h3 mat-dialog-title>{{ 'ORGANISATION_DETAILS.DOCUMENT_DELETE_MODAL.TITLE' | translate }}</h3>

<div mat-dialog-content >
    <ng-container *ngIf="!error; else errorTemplate">
        <div class="delete-modal">
            <div class="text-container">
                {{ 'ORGANISATION_DETAILS.DOCUMENT_DELETE_MODAL.TEXT' | translate }}
            </div>
            <div>
                {{ document.externalReference | json}}
            </div>
        </div>
    </ng-container>
</div>

<div mat-dialog-actions align="end">
	<dlt-forms-button (clicked)="onCancel()" [buttonAttribute]="cancelButtonAttribute" />

	<ng-container *hideIfUnauthorized="PermissionType.OrganisationContribute">
		<app-document-delete-button (clicked)="onSave()" [document]="document" />
	</ng-container>
</div>

<ng-template #errorTemplate>
    <dlt-notification-box [text]="error"></dlt-notification-box>
</ng-template>
