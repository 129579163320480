import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { BreadcrumbItem } from '../../models/breadcrumb-item.models';
import { BreadcrumbService } from '../../services/breadcumb.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
	selector: 'dlt-breadcrumb',
	templateUrl: 'breadcrumb.component.html',
	styleUrls: ['breadcrumb.component.css'],
	standalone: true,
	imports: [NgIf, NgClass, NgFor, RouterLink, TranslateModule]
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	private readonly detroy$ = new Subject<void>();

	breadcrumbItems: BreadcrumbItem[] | null = [];
	hasPageTitle = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private breadcrumbService: BreadcrumbService
	) {
		this.router.events
			.pipe(filter((event: Event) => event instanceof NavigationEnd))
			.pipe(takeUntil(this.detroy$))
			.subscribe(() => {
				this.breadcrumbItems = this.breadcrumbService.createBreadcrumbs(this.activatedRoute.root);
			});
	}

	ngOnInit(): void {
		this.breadcrumbItems = this.breadcrumbService.createBreadcrumbs(this.activatedRoute.root);
	}

	ngOnDestroy(): void {
		this.detroy$.next();
		this.detroy$.complete();
	}
}
