import { Component, Inject, OnDestroy, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, finalize, forkJoin, takeUntil } from 'rxjs';
import { LoadingDataIndicatorComponent, NotificationBoxComponent, PermissionType } from '@dnb/supervision-core/src/lib/dlt-ui';
import {
	HideIfUnauthorizedDirective,
	HttpServiceError,
	IOrganisationProfileService,
	ORGANISATION_PROFILE_SERVICE,
	PageHeaderComponent,
	USER_PERMISSIONS_SERVICE,
	UserPermissionsService
} from 'src/app/shared';
import { AppSidebarComponent } from '../../components';
import { SidebarFeatures, initialSidebarFeatures } from '../../models';
import { LanguageService } from 'src/app/language.service';

@Component({
	selector: 'app-app-layout',
	standalone: true,
	imports: [
		CommonModule,
		AppSidebarComponent,
		PageHeaderComponent,
		AppSidebarComponent,
		HideIfUnauthorizedDirective,
		LoadingDataIndicatorComponent,
		NotificationBoxComponent,
		TranslateModule,
		RouterModule
	],
	templateUrl: './app-layout.component.html',
	schemas: []
})
export class AppLayoutComponent implements OnDestroy {
	private readonly destroy$ = new Subject<void>();

	public PermissionType = PermissionType;
	public features: SidebarFeatures = initialSidebarFeatures;
	public loading = true;
	public error = '';

	constructor(
		private languageService: LanguageService,
		@Inject(ORGANISATION_PROFILE_SERVICE) private organisationProfileService: IOrganisationProfileService,
		@Inject(USER_PERMISSIONS_SERVICE) private readonly userPermissionService: UserPermissionsService
	) {
		effect(() => {
			const currentLang = this.languageService.currentLang();
			this.languageService.loadTranslations(currentLang);
		});
	}

	ngOnInit(): void {
		const profile$ = this.organisationProfileService.getOrganisationProfile();
		const permission$ = this.userPermissionService.initialize();

		forkJoin([profile$, permission$])
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe({
				next: ([profile]) => {
					const { hasDocumentUpload: documents, shareholderStructureEnabled: shareholders } = profile;
					this.features = { ...this.features, documents, shareholders };
				},
				error: (error: Error) => {
					this.error = error instanceof HttpServiceError ? error.translationToken : error.message;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
