import { Component, Inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, finalize, forkJoin, startWith, switchMap, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import {
	EntityTableComponent,
	InfoBoxComponent,
	LoadingDataIndicatorComponent,
	MarkdownDirective,
	NotificationBoxComponent,
	PermissionType
} from '@dnb/supervision-core/src/lib/dlt-ui';
import { DateStringPipe, DropdownComponent, FormOption } from '@dnb/supervision-core/src/lib/dlt-forms';
import {
	ButtonSize,
	HideIfUnauthorizedDirective,
	HttpServiceError,
	IOrganisationProfileService,
	LiveUpdateNotificationComponent,
	ORGANISATION_PROFILE_SERVICE
} from 'src/app/shared';
import {
	DocumentDeleteButtonComponent,
	DocumentDownloadButtonComponent,
	DocumentDownloadModalComponent,
	DocumentUploadButtonComponent,
	DocumentUploadModalComponent
} from '../../components';
import { DOCUMENTS_HUB_SERVICE, DOCUMENTS_SERVICE } from '../../dependency-injection';
import { IDocumentService } from '../../interfaces';
import { DocumentUploadModalContext, IOrganisationRelation, IDocumentType, IDocument, DocumentDownloadModalContext, DocumentDeleteModalComponentContext } from '../../models';
import { DocumentsFilterPipe } from '../../pipes';
import { DocumentsHubService } from '../../services';
import { SignalRHubConnectionState } from 'src/app/core';
import { LanguageService } from 'src/app/language.service';
import { DocumentDeleteModalComponent } from "../../components/document-delete-modal/document-delete-modal.component";

@Component({
	selector: 'app-documents',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DateStringPipe,
		HideIfUnauthorizedDirective,
		NotificationBoxComponent,
		InfoBoxComponent,
		LoadingDataIndicatorComponent,
		LiveUpdateNotificationComponent,
		EntityTableComponent,
		DocumentDeleteButtonComponent,
		DocumentDownloadButtonComponent,
		DocumentUploadButtonComponent,
		DocumentsFilterPipe,
		MarkdownDirective,
		DropdownComponent,
		TranslateModule,
		JsonPipe,
	],
	providers: [DocumentsHubService],
	templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit, OnDestroy {
	@Input() compactLayout: boolean = false;

	size = ButtonSize.Small;

	private readonly destroy$ = new Subject<void>();
	private readonly refreshSubject = new Subject<void>();
	private readonly relationSubject = new BehaviorSubject<IOrganisationRelation | null>(null);
	private documentTypes: IDocumentType[] = [];
	private hash: string;

	public PermissionType = PermissionType;
	public initialized = false;
	public loading = false;
	public error = '';
	public documents = signal<IDocument[]>([]);
	public selectedDocumentType!: string;
	public dummyform!: UntypedFormGroup;
	public documentTypeFilterOptions!: FormOption[];
	public hubState$: Observable<SignalRHubConnectionState | null>;
	public hubError$: Observable<Error | null>;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private languageService: LanguageService,
		@Inject(DOCUMENTS_SERVICE) private documentService: IDocumentService,
		@Inject(ORGANISATION_PROFILE_SERVICE) private organisationProfileService: IOrganisationProfileService,
		@Inject(DOCUMENTS_HUB_SERVICE) private documentHubService: DocumentsHubService
	) {}

	ngOnInit(): void {
		this.dummyform = this.formBuilder.group({ filter: '' });
		this.setupLoadDataSubscription();

		this.hubState$ = this.documentHubService.state$.pipe(takeUntil(this.destroy$), distinctUntilChanged());
		this.hubError$ = this.documentHubService.error$.pipe(takeUntil(this.destroy$), distinctUntilChanged());

		this.documentHubService.fileAvailableForDownload$.subscribe((event) => {
			const documentToUpdate = this.documents().find((docs) => docs.fileId === event.fileId);
			documentToUpdate.readyForDownload = true;
			documentToUpdate.externalReference = event.externalFileName;

			this.documents.update((docs) => docs.map((doc) => (doc.fileId === event.fileId ? documentToUpdate : doc)));
		});

		this.documentHubService.start();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	@Input()
	set relation(value: IOrganisationRelation | null) {
		this.updateRelation(value);
	}

	refresh(): void {
		this.refreshSubject.next();
	}

	updateRelation(relation: IOrganisationRelation | null): void {
		this.relationSubject.next(relation);
	}

	onUploadDocument(documentType: string): void {
		const enabledDocumentTypes = this.documentTypes.filter((dt) => dt.uploadEnabled);
		const currentDcumentType = enabledDocumentTypes.filter((dt) => dt.name == documentType);

		if (currentDcumentType.length == 0) {
			return;
		}

		const data: DocumentUploadModalContext = {
			documentTypeName: currentDcumentType[0].name,
			documentTypes: enabledDocumentTypes,
			relationId: this.relationSubject.getValue()?.id ?? null,
			hash: this.hash
		};

		const dialogConfig: MatDialogConfig<DocumentUploadModalContext> = {
			data,
			width: '740px',
			disableClose: true,
			autoFocus: false
		};

		this.dialog
			.open(DocumentUploadModalComponent, dialogConfig)
			.afterClosed()
			.subscribe((newDoc: IDocument) => {
				this.documents().forEach((doc, index) => {
					if (doc.documentType === newDoc.documentType){
						this.documents()[index] = newDoc;
					}
				});
			});
	}

	onDownloadDocument(document: IDocument): void {
		const { fileId, title: fileName } = document;

		const data: DocumentDownloadModalContext = {
			fileId,
			fileName,
			connected: this.documentHubService.connected
		};

		const dialogConfig: MatDialogConfig<DocumentDownloadModalContext> = {
			data,
			width: '640px',
			disableClose: true,
			autoFocus: false
		};

		this.dialog.open(DocumentDownloadModalComponent, dialogConfig).afterClosed().subscribe();

	}

	onDeleteDocument(document: IDocument): void {
		const data: DocumentDeleteModalComponentContext = {
			document: document
		};

		const dialogConfig: MatDialogConfig<DocumentDeleteModalComponentContext> = {
			data,
			width: '640px',
			disableClose: true,
			autoFocus: false
		};

		this.dialog.open(DocumentDeleteModalComponent, dialogConfig).afterClosed()
			.subscribe((closedOnDelete) => {
				if (closedOnDelete) {
					this.refreshSubject.next();
				}
			});
	}

	onDocumentTypeFilterChanged(documentType: string) {
		this.selectedDocumentType = documentType;
	}

	setupLoadDataSubscription(): void {
		const relation$ = this.relationSubject.pipe(distinctUntilChanged());
		const refresh$ = this.refreshSubject.pipe(startWith(null as void));

		combineLatest([relation$, refresh$])
			.pipe(
				takeUntil(this.destroy$),
				switchMap(([relation]) => {
					this.loading = true;
					this.error = '';

					const relationId = relation?.id ?? null;
					const relationRole = relation?.role ?? null;

					return forkJoin({
						documents: this.documentService.getDocuments(relationId),
						documentTypes: this.documentService.getDocumentTypes(relationRole),
						hash: this.organisationProfileService.getOrganisationProfileHash()
					}).pipe(finalize(() => (this.loading = false)));
				})
			)
			.subscribe({
				next: ({ documents, documentTypes, hash }) => {
					this.hash = hash;
					this.documentTypes = documentTypes;
					this.documentTypeFilterOptions = this.mapDocumentTypesToFormOptions(documentTypes);

					this.documents.set(this.combineDocumentWithTypes(documents, documentTypes) as IDocument[]);
					this.initialized = true;
				},
				error: (error: Error) => {
					this.error = error instanceof HttpServiceError ? error.translationToken : error.message;
				}
			});
	}

	getFormat() {
		return this.languageService.getFormat;
	}

	private combineDocumentWithTypes(documents: IDocument[], documentTypes: IDocumentType[]): Partial<IDocument>[] {
		const result: Partial<IDocument>[] = [];

		documentTypes.forEach((documentType) => {
			const filteredDocuments = documents.filter((document) => document.documentType === documentType.name);

			if (filteredDocuments.length === 0) {
				if (documentType.uploadEnabled) {
					result.push({ documentType: documentType.name, uploadEnabled: true });
				}
			} else {
				filteredDocuments.forEach((document) => {
					document.uploadEnabled = documentType.uploadEnabled;
					result.push(document);
				});
			}
		});

		return result;
	}

	private mapDocumentTypesToFormOptions(documentTypes: IDocumentType[]): FormOption[] {
		return documentTypes.map(
			(documentType): FormOption => ({
				id: documentType.name,
				description: documentType.name
			})
		);
	}
}
