import { Component, DestroyRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, Event, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MijndnbUiModule } from '@dnb/mijndnb-ui';
import { filter } from 'rxjs';
import { LanguageService } from './language.service';
import { AppLoaderComponent, BreadcrumbComponent } from './shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [CommonModule, MijndnbUiModule, BreadcrumbComponent, AppLoaderComponent, TranslateModule, RouterOutlet]
})
export class AppComponent {
	private readonly _destroyRef = inject(DestroyRef);

	initialized = false;
	error = '';

	constructor(
		readonly languageService: LanguageService,
		private readonly router: Router
	) {
		languageService.initialize()
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe(() => {
				this.initialized = true;
			});

			this.router.events
			.pipe(filter((routerEvent: Event) => routerEvent instanceof NavigationEnd))
			.pipe(takeUntilDestroyed())
			.subscribe((routerEvent) => {
				if (routerEvent instanceof NavigationEnd && routerEvent.url.indexOf('#') === -1) {
					window.scrollTo(0, 0);
				}
			});
	}

	showUI(): boolean {
		return this.initialized;
	}
}
