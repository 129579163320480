import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationBoxComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { Subject, finalize, takeUntil } from 'rxjs';
import { IInternalService } from '../../interfaces';
import { Router } from '@angular/router';
import { ORGANISATION_DETAILS_INTERNAL } from '../../dependency-injection';

@Component({
  selector: 'app-internal',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, NotificationBoxComponent, TranslateModule],
  templateUrl: './internal.component.html',
  styleUrls: ['./internal.component.scss']
})
export class InternalComponent {
@Input() companyIdType: string;
@Input() companyId: string;

loading = false;
errorMessage = '';
authenticated: boolean;

private readonly destroy$ = new Subject<void>();

constructor(@Inject(ORGANISATION_DETAILS_INTERNAL) private interalService: IInternalService, private router: Router){}

ngOnInit(): void {
  this.loading = true;
  this.interalService
    .authenticate({companyId: this.companyId, companyIdType: this.companyIdType})
    .pipe(
      takeUntil(this.destroy$),
      finalize(() => (this.loading = false))
    )
    .subscribe({
      next: (result) => {
        this.authenticated = true;
        sessionStorage.setItem('internalAccessToken', result.accessToken)
        this.loading = false;
        this.router.navigate(['/organisation'])
      },
      error: (error: Error) => {
        this.authenticated = false;
        this.errorMessage = error.message;
        this.loading = false;
      }
    });
}

ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
}
}
