import { Component, Input, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { IOption } from '../../models';

@Component({
	selector: 'app-form-control-select',
	standalone: true,
	imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatInputModule, TranslateModule],
	templateUrl: './form-control-select.component.html',
	styleUrls: ['./form-control-select.component.scss']
})
export class FormControlSelectComponent implements OnInit {
	@Input({ required: true }) controlName: string;
	@Input() label: string | null = null;
	@Input() required: boolean = false;
	@Input() options: IOption[] = [];

	constructor(@Optional() private parentForm: FormGroupDirective) {}

	ngOnInit(): void {
		if (!this.parentForm) {
			throw new Error('The app-form-control-select component requires a parent formGroup directive.');
		}
	}

	get formGroup(): FormGroup {
		return this.parentForm.form;
	}

	get control(): any {
		return this.formGroup.get(this.controlName);
	}

	get placeHolder(): string {
		return 'FORM.OPTION_SET_MAKE_A_CHOICE';
	}

	get errorRequired(): string {
		return 'ERRORS.REQUIRED';
	}
}
