import { Component, Input, Inject, effect, Optional } from '@angular/core';
import { NgIf } from '@angular/common';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { LanguageService } from 'src/app/language.service';

const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY'
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};

const DATE_INPUTS: { [key: string]: string } = {
	en: 'DD/MM/YYYY',
	nl: 'DD-MM-YYYY'
};

@Component({
	selector: 'app-form-control-date',
	standalone: true,
	imports: [TranslateModule, NgIf, MatInputModule, MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, MatNativeDateModule],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: CUSTOM_DATE_FORMATS
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true, strict: true }
		}
	],
	templateUrl: './form-control-date.component.html',
	styleUrls: ['./form-control-date.component.scss']
})
export class FormControlDateComponent {
	@Input({ required: true }) controlName: string;
	@Input() label: string | null = null;
	@Input() required: boolean = false;

	placeHolder: string = '';

	constructor(
		@Optional() private parentForm: FormGroupDirective,
		private languageService: LanguageService,
		private dateAdapter: DateAdapter<any>,
		@Inject(MAT_DATE_LOCALE) private dateLocale: string,
		@Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats
	) {
		if (!this.parentForm) {
			throw new Error('The app-form-control-date component requires a parent formGroup directive.');
		}

		effect(() => {
			const currentLocal = this.languageService.currentLang();
			this.dateLocale = currentLocal;
			this.dateAdapter.setLocale(this.dateLocale);

			const dateInput = this.getDateInput(currentLocal);
			this.placeHolder = dateInput;
			this.dateFormats.parse.dateInput = dateInput;
		});
	}

	onDateChange(event: MatDatepickerInputEvent<moment.Moment | null>) {
		const { value } = event;
		if (value) {
			const formatedValue = value.format('YYYY-MM-DD');
			this.control.setValue(formatedValue);
		}
	}

	get formGroup(): FormGroup {
		return this.parentForm.form;
	}

	get control(): any {
		return this.formGroup.get(this.controlName);
	}

	get errorRequired(): string {
		return 'ERRORS.REQUIRED';
	}

	private getDateInput(locale: string): string {
		return DATE_INPUTS[locale] || '';
	}
}
