export interface IDocumentResponse {
	documentInfoList: IDocumentItemResponse[];
}

interface IDocumentItemResponse {
	fileId: string;
	title: string | null;
	fileName: string | null;
	contentType: string | null;
	readyForDownload: boolean;
	deleteDocumentEnabled: boolean;
	metadata: IDocumentItemMetadataResponse | null;
}

interface IDocumentItemMetadataResponse {
	documentDate: string | null;
	documentType: string | null;
	externalFilename: string | null;
	submittedOn: string;
	year: number | null;
}

export interface IDocument {
	fileId: string;
	title: string | null;
	documentType: string | null;
	externalReference?: string;
	submittedOn?: string;
	readyForDownload: boolean;
	deleteDocumentEnabled: boolean | null;

	//view
	uploadEnabled?: boolean | null;
	isDownloading?: boolean | null;
}

export function mapResponseToDocuments(response: IDocumentResponse): IDocument[] {
	return response.documentInfoList.map((document): IDocument => {
		const {
			fileId,
			title,
			metadata: { externalFilename: externalReference, documentType, submittedOn },
			readyForDownload,
			deleteDocumentEnabled
		} = document;

		return {
			fileId,
			title,
			externalReference,
			documentType,
			submittedOn,
			readyForDownload,
			deleteDocumentEnabled
		};
	});
}
