import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.authenticationServer.clientId,
			authority: environment.authenticationServer.authority,
			knownAuthorities: environment.authenticationServer.knownAuthorities, // array of URIs that are known to be valid
			redirectUri: environment.authenticationServer.redirectUri,
			postLogoutRedirectUri: environment.authenticationServer.postLogoutRedirectUri,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: BrowserCacheLocation.SessionStorage,
			storeAuthStateInCookie: isIE // set to true for IE 11
		}
	});
}
