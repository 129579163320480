import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IFieldMapping } from '../models';

export class OrganisationDetailsFormHelperService {
	public static setValidatorsForFormControl(formControl: UntypedFormControl, entityRule: IFieldMapping): void {
		const validators = [];

		if (entityRule.isModifiable) {
			if (entityRule.isRequired === true) {
				validators.push(Validators.required);
			}

			if (entityRule.maxLength != null && typeof entityRule.maxLength === 'number') {
				validators.push(Validators.maxLength(entityRule.maxLength));
			}

			if (entityRule.regExValidationPattern != null) {
				validators.push(this.customPatternValidator(entityRule.regExValidationPattern, entityRule.regExValidationWarning));
			}
		}
		formControl.setValidators(validators);
		formControl.updateValueAndValidity();
	}

	public static setValidatorsForFormGroup(formGroup: UntypedFormGroup, fieldMappings: IFieldMapping[]): void {
		if (fieldMappings == null) {
			return;
		}

		fieldMappings.forEach((fieldMapping) => {
			const formControl = formGroup.get(fieldMapping.propertyName) as UntypedFormControl;
			if (formControl == null) {
				// no such property in the form, continue to next item with return
				return;
			}

			this.setValidatorsForFormControl(formControl, fieldMapping);
		});
	}

	/** 
    wrapper validator around the Validators.pattern validator function, adding a custom message to the result object in case the control is invalid.
    */
	private static customPatternValidator = function (pattern: string, message: string) {
		const corePatternValidator = Validators.pattern(pattern);

		return function (control: AbstractControl) {
			const coreResult = corePatternValidator(control);
			if (coreResult != null) {
				coreResult['pattern'].message = message;
			}

			return coreResult;
		};
	};
}
