/**
 * Class used to support in metric tracking for application insights.
 */
export class DurationMetric {
	private _start: Date;
	private _end: Date;
	private _duration: number;
	private _name: string;

	constructor(name: string) {
		this._name = name;
	}

	public Start(): void {
		this._start = new Date();
		this._end = null;
		this._duration = null;
	}

	public Stop(): void {
		if (!this._start) {
			// ignore when Start has not been called.
			return;
		}
		this._end = new Date();
		this._duration = null;
	}

	public get Name(): string {
		return this._name;
	}

	/**
	 * Returns the rounded difference in seconds between the moment Start was called and the moment Stop was called.
	 * Returns -1 when either has not been called or not called in the correct order, e.g. Stop before Start
	 */
	public get DurationInSeconds(): number {
		if (!this._start || !this._end) {
			// Start and/or Stop not called correctly
			return -1;
		}

		if (this._duration == null) {
			this._duration = Math.round((this._end.getTime() - this._start.getTime()) / 1000);
		}

		return this._duration;
	}
}
