import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SignalRHubConnectionFactory } from './signalr-hub-connection-factory';
import { SignalRHttpClientWrapper } from '../http-client';
import { environment } from 'src/environments/environment';

export function createSignalRHubConnectionFactory(httpClient: HttpClient): SignalRHubConnectionFactory {
	const connectionFactory = (url: string): HubConnection => {
		return new HubConnectionBuilder()
			.withUrl(url, {
				httpClient: new SignalRHttpClientWrapper(httpClient, {
					protectedResources: [`${environment.apiUrl}/*`]
				})
			})
			.configureLogging(LogLevel.Error)
			.withAutomaticReconnect()
			.build();
	};

	return new SignalRHubConnectionFactory(connectionFactory);
}
