import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();

	protectedResourceMap.set(`${environment.apiUrl}/*`, [...environment.authenticationServer.scopes]);
	protectedResourceMap.set(`${environment.mijndnb.mijnDNBBaseUri}/api`, [environment.mijndnb.userProfileApiScope]);

	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			extraQueryParameters: {
				idps: environment.authenticationServer.idpFilter.join(',')
			}
		},
		protectedResourceMap
	};
}
