import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthError, EventMessage, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MijndnbAuthService } from '@dnb/mijndnb-auth';
import { DigiDFlowHandlerService } from './digid-flow-handler.service';

@Injectable({
	providedIn: 'root'
})
export class MsalMessageHandlerService implements OnDestroy {
	private readonly destroy$ = new Subject<void>();

	constructor(
		private msalBroadcastService: MsalBroadcastService,
		private authService: MijndnbAuthService,
		private digiDFlowHandlerService: DigiDFlowHandlerService
	) {}

	init(): Promise<void> {
		return new Promise<void>((resolve) => {
			this.msalBroadcastService.inProgress$
				.pipe(
					filter((status: InteractionStatus) => status === InteractionStatus.None),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					this.authService.checkAccount();
				});

			this.msalBroadcastService.msalSubject$
				.pipe(
					filter((msg: EventMessage) => msg.error instanceof AuthError),
					takeUntil(this.destroy$)
				)
				.subscribe((result: EventMessage) => {
					this.handleLoginFailure(<AuthError>result.error);
				});

			resolve();
		});
	}

	private handleLoginFailure(error: AuthError): void {
		// Learn more about Azure B2C error codes at https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
		const localAccountUserCancellation = 'AADB2C90091';

		if (error instanceof AuthError) {
			// Log user cancellation when user cancels during local signup/signin
			if (error.message.includes(localAccountUserCancellation)) {
				console.error(error);
			}

			// Handle DigiD
			this.digiDFlowHandlerService.handle(error);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}

export function InitializeMsalMessageHandler(msalMessageHandler: MsalMessageHandlerService) {
	return (): Promise<any> => {
		return msalMessageHandler.init();
	};
}
