import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class InternalInterceptor implements HttpInterceptor {
	urlsToUse: Array<string>;

	constructor() {
		this.urlsToUse = ['v1/organisationdetails/'];
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const accessToken = sessionStorage.getItem('internalAccessToken');
		if (accessToken !== null && this.isValidRequestForInterceptor(req.url)) {
			const authreq = req.clone({
				headers: req.headers.append('x-internal-access-token', accessToken)
			});
			return next.handle(authreq);
		}
		return next.handle(req);
	}

	private isValidRequestForInterceptor(requestUrl: string): boolean {
		const positionIndicator: string = 'api/';
		const position = requestUrl.indexOf(positionIndicator);
		if (position > 0) {
			const destination: string = requestUrl.substring(position + positionIndicator.length);
			for (const address of this.urlsToUse) {
				if (new RegExp(address).test(destination)) {
					return true;
				}
			}
		}
		return false;
	}
}
