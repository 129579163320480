import { IMomentService } from '@dnb/supervision-core/src/lib/dlt-ui';
import { LongDateFormatKey } from 'moment';
import * as moment from 'moment-timezone';

export class MomentService implements IMomentService {
	getMoment(momentInput?: string | Date): moment.Moment {
		return moment(momentInput);
	}

	getNowMomentInTimeZone(timeZone: string): moment.Moment {
		return moment.tz(timeZone);
	}

	getMomentInTimeZone(momentInput: string | Date, timeZone: string): moment.Moment {
		return moment.tz(momentInput, timeZone);
	}

	today(): moment.Moment {
		return moment().hours(0).minutes(0).seconds(0).milliseconds(0);
	}

	longDateFormat(key: LongDateFormatKey): string {
		return moment.localeData().longDateFormat(key);
	}

	format(value: any, l: string): any {
		return moment(value).format(l);
	}
}
