import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
	selector: 'app-sidebar-arrow',
	standalone: true,
	templateUrl: './app-sidebar-arrow.component.html',
	styleUrls: ['./app-sidebar-arrow.component.scss']
})
export class AppSidebarArrowComponent implements OnInit {
	@ViewChild('template', { static: true }) template: TemplateRef<unknown> | undefined;

	constructor(private viewContainerRef: ViewContainerRef) {}

	ngOnInit() {
		if (this.template) {
			this.viewContainerRef.createEmbeddedView(this.template);
		}
	}
}
