import { Injectable } from '@angular/core';
import { FormOption, IOptionSetService, LikertAttribute, OptionSetAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OptionSetMockedService implements IOptionSetService {
	getOptionSetText(entityName: string, fieldName: string, id: string): string {
		log('getOptionSetText', entityName, fieldName, id);

		return '';
	}

	getOptionSet(entityName: string, fieldName: string): Observable<any[]> {
		log('getOptionSet', entityName, fieldName);

		if (entityName === 'ilx_functionarynottobeassessed') {
			return of([
				{
					id: '119140000',
					resourceKey: 'LBLMOG-SALUTATION-VAL-MR'
				},
				{
					id: '119140001',
					description: 'LBLMOG-SALUTATION-VAL-MRS'
				}
			]);
		}

		return of([]);
	}

	getOptionSetFromAttribute(attribute: OptionSetAttribute): Observable<any[]> {
		log('getOptionSetFromAttribute', attribute);

		return of([]);
	}

	getOptionsFromLikertAttribute(attribute: LikertAttribute): Observable<FormOption[]> {
		log('getOptionsFromLikertAttribute', attribute);

		return of([]);
	}
}

function log(functionName: string, entityNameOrAttribute: string | LikertAttribute | OptionSetAttribute, fieldName?: string, id?: string): void {
	console.group(`OptionSetService::${functionName}`);

	if (typeof entityNameOrAttribute === 'string') {
		console.log('entityName: ', entityNameOrAttribute);
		console.log('fieldName: ', fieldName);
		if (id !== undefined) {
			console.log('id: ', id);
		}
	} else {
		console.log('attribute: ', entityNameOrAttribute);
	}

	console.groupEnd();
}
