<nav *ngIf="breadcrumbItems" aria-label="breadcrumb" class="breadcrumb" [ngClass]="{ 'with-page-header': hasPageTitle }">
	<div class="container">
		<ul class="breadcrumb-list" itemscope itemtype="http://schema.org/BreadcrumbList">
			<ng-container *ngFor="let breadcrumbItem of breadcrumbItems; let itemIndex = index">
				<ng-container *ngIf="breadcrumbItems.length - 1 != itemIndex; else lastItem">
					<li class="breadcrumb-list__item" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
						<a *ngIf="!breadcrumbItem.absoluteUrl" itemprop="item" [routerLink]="[breadcrumbItem.url]">
							<span itemprop="name">{{ breadcrumbItem.label | translate }}</span>
						</a>
						<a *ngIf="breadcrumbItem.absoluteUrl" itemprop="item" [href]="[breadcrumbItem.url]">
							<span itemprop="name">{{ breadcrumbItem.label | translate }}</span>
						</a>
						<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
							<path
								fill="currentColor"
								d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
						</svg>
						<meta itemprop="position" [content]="itemIndex" />
					</li>
				</ng-container>
				<ng-template #lastItem>
					<li
						class="breadcrumb-list__item breadcrumb-list__item--current-page"
						itemprop="itemListElement"
						itemscope
						itemtype="http://schema.org/ListItem">
						<span itemprop="name" aria-current="page">{{ breadcrumbItem.label | translate }}</span>
						<meta itemprop="position" [content]="itemIndex" />
					</li>
				</ng-template>
			</ng-container>
		</ul>
	</div>
</nav>
