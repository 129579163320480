<div class="row">
	<div class="col-xs-12">
		<span *ngIf="contactChannel.entityMapping.typeLabelResourceKey" class="contact-channel-header">{{
			contactChannel.entityMapping.typeLabelResourceKey | translate
		}}</span>
		<dlt-info-button *ngIf="hasHelpInformation" (toggle)="onToggleHelpInformation()"></dlt-info-button>
	</div>
</div>
<hr class="address mdnb-line" />
<dlt-info-box *ngIf="showHelpInformation" [content]="helpInformation" [translateContent]="true" (closeBox)="onCloseHelpInformation()"></dlt-info-box>
<ng-container *ngIf="showFields()">
	<app-organisation-entity-properties [formAttributes]="FormAttributes" />
</ng-container>
