import { Component } from '@angular/core';
import { DataEventBrokerService } from '@dnb/supervision-core';
import { OrganisationDetailsEvents, IOrganisationDetailsContentView } from '../models';

@Component({ template: '' })
export class OrganisationDetailsContentComponent {
	constructor(protected eventBroker: DataEventBrokerService) {}

	protected signalContentLoaded(loadedFromUrl: string, title: string, params: any = null): void {
		this.eventBroker.emit<IOrganisationDetailsContentView>(OrganisationDetailsEvents.ContentLoadedEvent, <IOrganisationDetailsContentView>{
			title: title,
			params: params,
			loadedFromUrl: loadedFromUrl
		});
	}
}
