import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbService, PageHeaderService } from '../../services';
import { BreadcrumbItem } from '../../models';

@Component({
	selector: 'app-page-header',
	standalone: true,
	imports: [NgIf, TranslateModule],
	templateUrl: 'page-header.component.html',
	styleUrls: ['page-header.component.css']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
	private readonly destroy$ = new Subject<void>();
	private _title: string | null = null;

	constructor(
		private pageHeaderService: PageHeaderService,
		private breadcrumbService: BreadcrumbService
	) {}

	ngOnInit(): void {
		// this event always comes before any optional pageHeaderService event, as this is triggered by the router
		this.breadcrumbService.breadCrumbUpdated$.pipe(takeUntil(this.destroy$)).subscribe((items: BreadcrumbItem[]) => {
			this.updateTitleFromBreadcrumbItems(items);
		});

		// this event could overwrite any values coming from the breadcrumbservice
		this.pageHeaderService.pageTitle$.pipe(takeUntil(this.destroy$)).subscribe((title) => {
			// set private field, so we do not execute the property setter code
			this._title = title;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.pageHeaderService.setPageHeaderTitle(null);
	}

	set title(value: string) {
		this._title = value;
		this.pageHeaderService.setPageHeaderTitle(value);
	}

	get title(): string | null {
		return this._title;
	}

	private updateTitleFromBreadcrumbItems(items: BreadcrumbItem[]): void {
		if (items == null || items.length == 0) {
			this.title = '';
			return;
		}

		const item = items[items.length - 1];
		this.title = item.noPageHeader ? null : item.label;
	}
}
