<ng-container *ngIf="layout === 'row'">
	<div class="row">
		<div class="col-xs-12 col-sm-7 col-md-6 col-lg-6">
			<dlt-entity-property-label [name]="formAttribute.displayName"></dlt-entity-property-label>
			<dlt-info-button *ngIf="formAttribute?.helpInformation" class="info-button-padding-left" (toggle)="onToggleHelpInformation()">
			</dlt-info-button>
			<dlt-info-button *ngIf="hasGlobalHelp" class="info-button-padding-left" (toggle)="onToggleGlobalHelp()"> </dlt-info-button>
		</div>
		<div class="col-xs-12 col-sm-5 col-md-6 col-lg-6">
			<ng-container *ngIf="!formAttribute.contentIsMarkdown; else markdown">
				<ng-container *ngIf="!formAttribute.contentIsDateTime; else dateTimeTemplate">
					<span *ngIf="formAttribute.translateContent">{{ formAttribute.value | translate }}</span>
					<span *ngIf="!formAttribute.translateContent">{{ formAttribute.value }}</span>
				</ng-container>
			</ng-container>
			<ng-template #markdown>
				<dlt-markdown [text]="formAttribute.content" [translateText]="false" class="no-top-margin"> </dlt-markdown>
			</ng-template>
			<ng-template #dateTimeTemplate>
				<span>{{ formAttribute.value | dltStringDate : getFormat() }}</span>
			</ng-template>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="layout === 'column'">
	<div class="row row-mg-b">
		<div class="col-md-12">
			<label class="label--column"
				><b>{{ formAttribute.displayName | translate }}</b></label
			>
			<dlt-info-button *ngIf="formAttribute?.helpInformation" class="info-button-padding-left" (toggle)="onToggleHelpInformation()">
			</dlt-info-button>
		</div>
		<div class="col-md-12">
			<ng-container *ngIf="formAttribute.content; else defaultText">
				<ng-container *ngIf="!formAttribute.contentIsMarkdown; else markdown">
					<ng-container *ngIf="!formAttribute.contentIsDateTime; else dateTimeTemplate">
						<span *ngIf="formAttribute.translateContent">{{ formAttribute.value | translate }}</span>
						<span *ngIf="!formAttribute.translateContent">{{ formAttribute.value }}</span>
					</ng-container>
				</ng-container>
				<ng-template #markdown>
					<dlt-markdown [text]="formAttribute.content" [translateText]="false" class="no-top-margin"> </dlt-markdown>
				</ng-template>
				<ng-template #dateTimeTemplate>
					<span>{{ formAttribute.value | dltStringDate : getFormat() }}</span>
				</ng-template>
			</ng-container>
			<ng-template #defaultText>-</ng-template>
		</div>
	</div>
</ng-container>
<dlt-info-box *ngIf="showHelpInformation" [content]="formAttribute.helpInformation!" [translateContent]="true" (closeBox)="OnCloseHelpInformation()">
</dlt-info-box>
