import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { IUserPermissionsService, MonitoringService, httpHandleError } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PermissionType } from '@dnb/supervision-core/src/lib/dlt-ui';

interface UserPermissionsResponse {
	permissions: string[];
}

@Injectable({
	providedIn: 'root'
})
export class UserPermissionsService implements IUserPermissionsService {
	private readonly profileUrl = `${environment.apiUrl}/v1/organisationdetails/user/permissions`;
	private userPermissions: Set<PermissionType> = new Set<PermissionType>();
	private isInitialized = false;

	constructor(
		private httpClient: HttpClient,
		private monitoringService: MonitoringService
	) {}

	initialize(): Observable<PermissionType[]> {
		return this.httpClient.get<UserPermissionsResponse>(this.profileUrl).pipe(
			map((response) => {
				const permissions = this.mapToPermissionTypes(response.permissions);
				this.userPermissions = new Set<PermissionType>(permissions);
				this.isInitialized = true;
				return [...permissions];
			}),
			catchError(httpHandleError(this.monitoringService))
		);
	}

	hasPermission(permission: PermissionType): boolean {
		if (!this.isInitialized) {
			throw new Error('User permissions not initialized. Call initialize first.');
		}

		return this.userPermissions.has(permission);
	}

	private mapToPermissionTypes(source: string[]): PermissionType[] {
		const validPermissions: PermissionType[] = [];

		for (const sourcePermission of source) {
			if (this.isValidPermissionType(sourcePermission)) {
				validPermissions.push(sourcePermission as PermissionType);
			} else {
				console.warn(`Invalid PermissionType: ${sourcePermission}`);
			}
		}

		return validPermissions;
	}

	private isValidPermissionType(value: string): boolean {
		return Object.values(PermissionType).includes(value as PermissionType);
	}
}
