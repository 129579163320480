/**
 * the interface of the data coming in from the api
 */
export interface IOrganisationMenuItem {
	id: string;
	title: string;
	titleIsResourceKey: boolean;
	toolTipResourceKey: string;
	icon: string;
	itemType: string;
	nestingLevel: number;
	expanded: boolean;
	relationGroupId: string;
	dataModelGroupId: string;
	relationId: string;
	role: string;
}

/**
 * the class the components work with, implementing the data interface
 */
export class OrganisationMenuItem implements IOrganisationMenuItem {
	id: string;
	title: string;
	titleIsResourceKey: boolean;
	toolTipResourceKey: string;
	icon: string;
	itemType: string;
	nestingLevel: number;
	expanded: boolean;
	relationGroupId: string;
	dataModelGroupId: string;
	relationId: string;
	role: string;

	url: string;
	visible: boolean;
	parentItem: OrganisationMenuItem;
	active: boolean;
	isParent: boolean;

	public static CreateFromJsonData(json: IOrganisationMenuItem): OrganisationMenuItem {
		const item = new OrganisationMenuItem();
		item.id = json.id;
		item.title = json.title;
		item.titleIsResourceKey = json.titleIsResourceKey;
		item.toolTipResourceKey = json.toolTipResourceKey;
		item.icon = json.icon;
		item.itemType = json.itemType;
		item.nestingLevel = json.nestingLevel;
		item.expanded = json.expanded;
		item.relationGroupId = json.relationGroupId;
		item.relationId = json.relationId;
		item.role = json.role;
		item.dataModelGroupId = json.dataModelGroupId;

		item.parentItem = null;
		item.url = null;
		item.active = false;
		item.isParent = false;

		return item;
	}
}
