export enum HttpStatusCode {
	Unknown = 0,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	Conflict = 409,
	PreConditionFailed = 412,
	PreConditionRequired = 428,
	TooManyRequests = 429,
	InternalServerError = 500,
	ServiceUnavailable = 503
}

const translationTokens: { [key in HttpStatusCode]: string } = {
	[HttpStatusCode.Unknown]: 'HTTP_ERRORS.UNKNOWN',
	[HttpStatusCode.BadRequest]: 'HTTP_ERRORS.BAD_REQUEST',
	[HttpStatusCode.Unauthorized]: 'HTTP_ERRORS.UNAUTHORIZED',
	[HttpStatusCode.Forbidden]: 'HTTP_ERRORS.FORBIDDEN',
	[HttpStatusCode.NotFound]: 'HTTP_ERRORS.NOT_FOUND',
	[HttpStatusCode.Conflict]: 'HTTP_ERRORS.CONFLICT',
	[HttpStatusCode.PreConditionFailed]: 'HTTP_ERRORS.PRECONDITION_FAILED',
	[HttpStatusCode.PreConditionRequired]: 'HTTP_ERRORS.PRECONDITION_REQUIRED',
	[HttpStatusCode.TooManyRequests]: 'HTTP_ERRORS.TOO_MANY_REQUESTS',
	[HttpStatusCode.InternalServerError]: 'HTTP_ERRORS.INTERNAL_SERVER_ERROR',
	[HttpStatusCode.ServiceUnavailable]: 'HTTP_ERRORS.SERVICE_UNAVAILABLE'
};

export function getTranslationTokenFromHttpStatusCode(code: HttpStatusCode): string {
	return translationTokens[code] ?? translationTokens[HttpStatusCode.Unknown];
}
