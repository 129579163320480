import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationEntityFormFactory } from 'src/app/shared/services/factories/organisation-entity-form.factory';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { ContactChannelBaseComponent } from '../contact-channel-base.component';
import { Guid, IAddress, IOrganisationObject } from 'src/app/shared/models';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfoBoxComponent, InfoButtonComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { OrganisationEntityPropertiesComponent } from '../../organisation-entity-properties/organisation-entity-properties.component';

@Component({
	selector: 'app-address-contact-channel',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, TranslateModule, InfoBoxComponent, InfoButtonComponent, OrganisationEntityPropertiesComponent],
	templateUrl: './address-contact-channel.component.html'
})
export class AddressContactChannelComponent extends ContactChannelBaseComponent {
	@Input() contactChannel!: IAddress;

	constructor(private formFactory: OrganisationEntityFormFactory) {
		super();
	}

	showFields(): boolean {
		return this.contactChannel.id !== undefined && this.contactChannel.id !== null && this.contactChannel.id !== Guid.Empty;
	}

	protected getOrganisationObjectAfterInit(): IOrganisationObject {
		return this.contactChannel;
	}

	protected createFormAttributes(): FormAttribute[] {
		return this.formFactory.CreatePostalAddressFormAttributes(this.contactChannel, false);
	}
}
