<app-page-header></app-page-header>

<div class="container">
	<ng-container *ngIf="!loading; else loadingTemplate">
		<ng-container *ngIf="!error; else errorTemplate">
			<div *hideIfUnauthorized="PermissionType.OrganisationSimulation" class="alert alert-warning" role="alert">
				{{ 'ORGANISATION_DETAILS.SIMULATION_HELPTEXT' | translate }}
			</div>
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<router-outlet></router-outlet>
				</div>
				<div class="col-xs-12 col-lg-3">
					<app-sidebar [features]="features"></app-sidebar>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>

<ng-template #loadingTemplate>
	<div class="component-main-content">
		<dlt-loading-data-indicator></dlt-loading-data-indicator>
	</div>
</ng-template>

<ng-template #errorTemplate>
	<div class="component-main-content">
		{{ error | translate }}
	</div>
</ng-template>
