import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IFieldMapping } from '../../models/field-mapping.model';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { DateAttribute } from '../../models/attributes/date-attribute.model';
import { TextAttribute } from '../../models/attributes/text-attribute.model';
import { LabelAttribute } from '../../models/attributes/label-attribute.model';
import { OptionSetAttribute } from '../../models/attributes/optionset-attribute.model';
import { Option } from '../../models/option.model';

@Injectable({
	providedIn: 'root'
})
export class FormAttributeFactoryService {
	/**
	 * Function to set validation properties that are shared by all sub classes.
	 * @param attr
	 * @param fieldMapping
	 */
	public SetValidation(attr: FormAttribute, fieldMapping: IFieldMapping): void {
		if (fieldMapping == null) {
			return;
		}

		attr.isRequired = fieldMapping.isRequired === true;
		attr.regexValidationPattern = fieldMapping.regExValidationPattern;
		attr.validationPatternWarning = fieldMapping.regExValidationWarning;
	}

	public CreateDateAttribute(name: string, displayName: string, fieldMapping: IFieldMapping = null!, dateStringValue = ''): DateAttribute {
		const attr = new DateAttribute();
		this.SetBaseAttributeValues(attr, name, displayName, fieldMapping);
		attr._type = 'dateTimeAttribute';
		attr.value = this.FormatDateForValue(dateStringValue);
		// todo: not sure when isDate would be false, could figure it out and document it, must be a 'forms' thing.
		attr.isDate = true;

		if (fieldMapping != null) {
			// note: the date past/present rules do not have a validator, they're used for configuring the datepicker.
			attr.mustBeInPast = fieldMapping.dateMustBeInPast;
			attr.mustBeInFuture = fieldMapping.dateMustBeInFuture;
			this.SetValidation(attr, fieldMapping);
		}

		return attr;
	}

	public CreateTextAttribute(name: string, displayName: string, fieldMapping: IFieldMapping = null!, content = ''): TextAttribute {
		const attr = new TextAttribute();
		this.SetBaseAttributeValues(attr, name, displayName, fieldMapping);
		attr._type = 'textAttribute';
		attr.value = content;

		if (fieldMapping != null) {
			attr.maxLength = fieldMapping.maxLength;
			this.SetValidation(attr, fieldMapping);
		}

		return attr;
	}

	public CreateLabelAttribute(name: string, displayName: string, fieldMapping: IFieldMapping, ...content: string[]): LabelAttribute {
		const attr = new LabelAttribute();
		this.SetBaseAttributeValues(attr, name, displayName, fieldMapping);
		const contentString = content.join(' ');
		attr.value = contentString;
		attr.content = contentString;
		attr._type = 'labelAttribute';

		if (fieldMapping.dataIsResourceKey === true) {
			attr.translateContent = true;
		}
		attr.contentIsMarkdown = fieldMapping.dataIsMarkdown === true;
		attr.contentIsDateTime = fieldMapping.type === 'DateTime';

		return attr;
	}

	/**
	 * Creates an Option array from a string array using each string value as both label and value for a single Option
	 * @param strings
	 */
	public CreateOptionsFromStrings(strings: string[]): Option[] {
		return strings.map((v) => {
			return <Option>{ label: v, value: v };
		});
	}

	public CreateOptionsFromLabelValuePairs(kvps: { label: string; value: string }[]): Option[] {
		return kvps.map((kvp) => {
			return <Option>{ label: kvp.label, value: kvp.value };
		});
	}

	public CreateOptionsForYearSelection(): Option[] {
		const currentYear = new Date().getFullYear();
		const options = [];
		// 10 years to select from including current
		for (let i = 0; i < 10; i++) {
			const value = (currentYear - i).toString();
			options.push(<Option>{ label: value, value: value });
		}
		return options;
	}

	public CreateOptionSetAttribute(
		name: string,
		displayName: string,
		value: string,
		options: Option[],
		fieldMapping: IFieldMapping = null!,
		entityName?: string
	): OptionSetAttribute {
		const attr = new OptionSetAttribute();
		this.SetBaseAttributeValues(attr, name, displayName, fieldMapping, true); // option set is always in edit mode
		this.SetValidation(attr, fieldMapping);
		attr._type = 'optionSetAttribute';
		attr.value = value;
		attr.options = options;
		attr.entityName = entityName!;
		// by default, assume all options are resource keys
		attr.translateOptions = true;
		return attr;
	}

	public CreateCustomAttribute(name: string, displayName: string, fieldMapping: IFieldMapping): FormAttribute {
		const attr = new FormAttribute();
		this.SetBaseAttributeValues(attr, name, displayName, fieldMapping);
		// render of 'input' UI is custom with this type
		attr._type = 'customAttribute';

		if (fieldMapping != null) {
			this.SetValidation(attr, fieldMapping);
		}

		return attr;
	}

	private FormatDateForValue(dateString: string): string {
		return dateString != null && dateString != '' ? moment(dateString).toISOString() : null!;
	}

	private GetHelpTextResourceKey(fieldMapping: IFieldMapping, editMode: boolean): string {
		if (fieldMapping == null) {
			return null!;
		}

		if (editMode && fieldMapping.editModeHelpTextResourceKey != null) {
			return fieldMapping.editModeHelpTextResourceKey;
		}

		return fieldMapping.helpTextResourceKey;
	}

	/**
	 * Function to set attribute properties that are shared by all sub classes.
	 * @param name
	 * @param displayName
	 */
	private SetBaseAttributeValues(attr: FormAttribute, name: string, displayName: string, fieldMapping: IFieldMapping, editMode = false): void {
		attr.attributeName = name;
		attr.displayName = (fieldMapping != null ? fieldMapping.labelResourceKey : null) || displayName;
		attr.layoutSettings = { useColumnLayout: true, supportWideLayout: false, formFieldsContainerWidth: '100%' };
		attr.helpInformation = this.GetHelpTextResourceKey(fieldMapping, editMode);
		attr.confirmationResourceKey = fieldMapping != null ? fieldMapping.confirmationResourceKey : null;
	}
}
