import { enableProdMode, importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MsalInterceptor } from '@azure/msal-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LogLevel } from '@dnb/mijndnb-auth';
import { MijndnbUiModule } from '@dnb/mijndnb-ui';
import { environment } from './environments/environment';
import { IHTTP_CLIENT } from './app/core/http/token';
import { CustomHttpClient } from './app/core/http/custom-http-client';
import { DLT_CORE_MOMENTSERVICE, DLT_UI_MARKDOWNSERVICE, IMarkdownService, IMomentService } from '@dnb/supervision-core/src/lib/dlt-ui';
import { DLT_FORMS_OPTIONSETSERVICE, IOptionSetService } from '@dnb/supervision-core/src/lib/dlt-forms';
import { AppComponent, AppRoutes } from './app';
import { CoreModule, AuthModule, SIGNALR_HUB_CONNECTION_FACTORY, createSignalRHubConnectionFactory } from './app/core';
import {
	USER_PERMISSIONS_SERVICE,
	UserPermissionsMockedService,
	UserPermissionsService,
	CustomTranslateLoader,
	MonitoringService,
	MomentService,
	OptionSetMockedService,
	OptionSetService,
	ORGANISATION_PROFILE_SERVICE,
	OrganisationProfileMockedService,
	OrganisationProfileService
} from './app/shared';
import { MarkdownService } from './app/shared/services/markdown.service';
import { MatDialogModule } from '@angular/material/dialog';
import {
	DOCUMENTS_HUB_SERVICE,
	DOCUMENTS_SERVICE,
	DocumentsHubMockedService,
	DocumentsHubService,
	DocumentsMockedService,
	DocumentsService
} from './app/documents';
import { ORGANISATION_DETAILS_INTERNAL } from './app/internal/dependency-injection';
import { InternalService } from './app/internal/containers/services/internal-service';
import { InternalInterceptor } from './app/core/authentication/internal/internal-interceptor';

const DEFAULT_LANGUAGE = 'en';
const USE_USER_PERMISSIONS_MOCKED_SERVICE = false;
const USE_ORGANISATION_DETAILS_MOCKED_SERVICE = false;
const USE_DOCUMENTS_MOCKED_SERVICE = false;
const USE_DOCUMENTS_HUB_MOCKED_SERVICE = false;
const USE_OPTION_SET_MOCKED_SERVICE = false;

if (environment.production) {
	enableProdMode();
}

export function CustomTranslateLoaderFactory(httpClient: HttpClient, monitoringService: MonitoringService) {
	return new CustomTranslateLoader(httpClient, monitoringService, [{ prefix: 'assets/i18n', suffix: '.json' }]);
}

function MomentServiceFactory(): IMomentService {
	return new MomentService();
}

function MarkdownServiceFactory(): IMarkdownService {
	return new MarkdownService();
}

function OptionSetServiceFactory(httpClient: HttpClient, monitoringService: MonitoringService): IOptionSetService {
	return USE_OPTION_SET_MOCKED_SERVICE ? new OptionSetMockedService() : new OptionSetService(httpClient, monitoringService);
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			CommonModule,
			CoreModule,
			BrowserModule,
			MatDialogModule,
			AuthModule,
			MijndnbUiModule.forRoot({
				mijnDnbBaseUrl: environment.mijndnb.mijnDNBBaseUri,
				mijnDnbCdnUrl: environment.production ? undefined : environment.mijndnb.mijnDNBCdnUri,
				idleInSeconds: parseInt(environment.idleInSeconds),
				timeOutInSeconds: parseInt(environment.timeOutInSeconds),
				loggingOptions: {
					logLevel: environment.production ? LogLevel.Off : LogLevel.All,
					logWithDate: !environment.production
				}
			}),
			TranslateModule.forRoot({
				defaultLanguage: DEFAULT_LANGUAGE,
				loader: {
					provide: TranslateLoader,
					useFactory: CustomTranslateLoaderFactory,
					deps: [HttpClient, MonitoringService]
				}
			})
		),
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: DLT_UI_MARKDOWNSERVICE,
			useFactory: MarkdownServiceFactory
		},
		{
			provide: USER_PERMISSIONS_SERVICE,
			useClass: USE_USER_PERMISSIONS_MOCKED_SERVICE ? UserPermissionsMockedService : UserPermissionsService
		},
		{
			provide: ORGANISATION_PROFILE_SERVICE,
			useClass: USE_ORGANISATION_DETAILS_MOCKED_SERVICE ? OrganisationProfileMockedService : OrganisationProfileService
		},
		{
			provide: IHTTP_CLIENT,
			useClass: CustomHttpClient
		},
		{
			provide: DLT_CORE_MOMENTSERVICE,
			useFactory: MomentServiceFactory
		},
		{
			provide: DLT_FORMS_OPTIONSETSERVICE,
			useFactory: OptionSetServiceFactory,
			deps: [HttpClient, MonitoringService]
		},
		{
			provide: DOCUMENTS_SERVICE,
			useClass: USE_DOCUMENTS_MOCKED_SERVICE ? DocumentsMockedService : DocumentsService
		},
		{
			provide: DOCUMENTS_HUB_SERVICE,
			useClass: USE_DOCUMENTS_HUB_MOCKED_SERVICE ? DocumentsHubMockedService : DocumentsHubService
		},
		{
			provide: SIGNALR_HUB_CONNECTION_FACTORY,
			useFactory: createSignalRHubConnectionFactory,
			deps: [HttpClient]
		},
		{
			provide: ORGANISATION_DETAILS_INTERNAL,
			useClass: InternalService
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InternalInterceptor,
			multi: true
		},
		provideRouter(AppRoutes, withComponentInputBinding()),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi())
	]
}).catch((err) => console.error(err));
