import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAttribute, ButtonType, DataEventBrokerService, FormsButtonComponent, PermissionType } from '@dnb/supervision-core/src/lib/dlt-ui';
import { IEventListener } from '@dnb/supervision-core/src/lib/dlt-ui/services/eventbroker.service';
import { HideIfUnauthorizedDirective } from '../../directives';

@Component({
	selector: 'app-form-submit-toolbar',
	standalone: true,
	imports: [CommonModule, FormsButtonComponent, HideIfUnauthorizedDirective],
	templateUrl: './form-submit-toolbar.component.html'
})
export class FormSubmitToolbarComponent implements OnDestroy {
	@Input() saveEnabled: boolean;
	@Input() saveHidden = false;
	@Input() permissionType: PermissionType;

	@Output() editCancelled = new EventEmitter<void>();

	private _barStyleClass = 'submit-bar';
	private saveStartListener: IEventListener;
	private saveEndListener: IEventListener;

	saveInProgress = false;

	constructor(eventBroker: DataEventBrokerService) {
		this.saveStartListener = eventBroker.subscribeToSaveStart(() => (this.saveInProgress = true));
		this.saveEndListener = eventBroker.subscribeToSaveEnd(() => (this.saveInProgress = false));
	}

	ngOnDestroy(): void {
		this.saveStartListener.ignore();
		this.saveEndListener.ignore();
	}

	@Input()
	set barStyleClass(cssClass: string) {
		if (cssClass != null) {
			this._barStyleClass = cssClass;
		}
	}

	public onCancelAndClose(): void {
		this.editCancelled.emit();
	}

	get barStyleClass(): string {
		return this._barStyleClass;
	}

	get CancelButton(): ButtonAttribute {
		return <ButtonAttribute>{
			text: 'ORGANISATION_DETAILS.CANCEL',
			size: 'medium',
			showIconLeft: true,
			iconLeft: 'fa fa-times',
			disabled: this.saveInProgress
		};
	}

	get SubmitButton(): ButtonAttribute {
		return <ButtonAttribute>{
			type: ButtonType.Submit,
			text: 'ORGANISATION_DETAILS.SAVE',
			size: 'medium',
			showIconLeft: true,
			iconLeft: this.getSubmitButtonIcon(),
			disabled: this.submitButtonDisabled
		};
	}

	private getSubmitButtonIcon(): string {
		if (this.saveInProgress) {
			return 'fa fa-spin fa-spinner';
		}
		return 'fa fa-check';
	}

	public get submitButtonDisabled(): boolean {
		return !this.saveEnabled || this.saveInProgress;
	}
}
