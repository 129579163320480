import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOrganisationObject, IEmailAddress } from 'src/app/shared/models';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { OrganisationEntityFormFactory } from 'src/app/shared/services/factories/organisation-entity-form.factory';
import { ContactChannelBaseComponent } from '../contact-channel-base.component';
import { OrganisationEntityPropertiesComponent } from '../../organisation-entity-properties/organisation-entity-properties.component';

@Component({
	selector: 'app-email-address-contact-channel',
	standalone: true,
	imports: [CommonModule, OrganisationEntityPropertiesComponent],
	templateUrl: './email-address-contact-channel.component.html'
})
export class EmailAddressContactChannelComponent extends ContactChannelBaseComponent {
	@Input() contactChannel!: IEmailAddress;

	constructor(private formFactory: OrganisationEntityFormFactory) {
		super();
	}

	protected getOrganisationObjectAfterInit(): IOrganisationObject {
		return this.contactChannel;
	}

	protected createFormAttributes(): FormAttribute[] {
		return this.formFactory.CreateEmailAddressFormAttributes(this.contactChannel, false);
	}
}
