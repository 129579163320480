export interface IOption {
	value: string;
	viewValue: string;
}

export function generateYearOptions(currentYear: number, length: number = 10): IOption[] {
	return Array.from({ length }, (_, index) => {
		const year = (currentYear - index).toString();
		return {
			value: year,
			viewValue: year
		};
	});
}
