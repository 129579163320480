import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataEventBrokerService } from '@dnb/supervision-core';
import { IAddress, IContactChannel, IFieldMapping } from '../models';
import { OrganisationDetailsFormHelperService, ObjectHelperService } from '../services';
import { OrganisationDetailsContentComponent } from './01-organisation-details-content.component';

@Component({ template: '' })
export abstract class OrganisationDetailsContentEditComponent extends OrganisationDetailsContentComponent implements OnDestroy {
	private readonly baseDestroyed$ = new Subject<void>();
	private formReady: boolean;

	editForm: UntypedFormGroup;

	constructor(
		eventBroker: DataEventBrokerService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected formBuilder: UntypedFormBuilder,
		protected translate: TranslateService
	) {
		super(eventBroker);

		this.formReady = false;
		this.editForm = null;

		translate.onLangChange.pipe(takeUntil(this.baseDestroyed$)).subscribe(() => this.handleLanguageChange());
	}

	ngOnDestroy(): void {
		this.baseDestroyed$.next();
		this.baseDestroyed$.complete();
	}

	get canSubmitForm(): boolean {
		return this.formReady === true && this.editForm.valid === true && this.editForm.pristine === false;
	}

	/**
	 * Calling this function with a true parameter will allow form submission if all other conditions are met, false will disable it.
	 * @param ready
	 */
	protected SetFormReady(ready: boolean): void {
		this.formReady = ready;
	}

	protected get CurrentLanguage(): string {
		return this.translate.currentLang;
	}

	protected CreateContactChannelFormArrayWithValidators(contactChannels: IContactChannel[]): UntypedFormArray {
		if (contactChannels == null) {
			contactChannels = [];
		}
		return this.formBuilder.array(
			contactChannels.map((cc) => this.CreateFormGroupAndSetValidators(ObjectHelperService.CopyObjectWithoutArrayProperties(cc), cc.fieldMappings))
		);
	}

	protected CreateAddressFormArrayWithValidators(addressContactChannels: IAddress[]): UntypedFormArray {
		if (addressContactChannels == null) {
			addressContactChannels = [];
		}
		return this.formBuilder.array(
			addressContactChannels.map((address) => {
				const copy = ObjectHelperService.CopyObjectWithoutArrayProperties(address);
				// only activate validators when address is actually shown
				if (address.showAddress) {
					return this.CreateFormGroupAndSetValidators(copy, address.fieldMappings);
				} else {
					return this.CreateFormGroupWithoutValidators(copy);
				}
			})
		);
	}

	protected CreateFormGroupWithoutValidators(config: any): UntypedFormGroup {
		const group = this.formBuilder.group(config);
		return group;
	}

	protected CreateFormGroupAndSetValidators(config: any, fieldMappings: IFieldMapping[]): UntypedFormGroup {
		const group = this.formBuilder.group(config);
		OrganisationDetailsFormHelperService.setValidatorsForFormGroup(group, fieldMappings);
		return group;
	}

	private handleLanguageChange(): void {
		if (this.editForm == null) {
			// form has not been created yet, could occur if the language change occurs before the creation of the form.
			return;
		}
	}
}
