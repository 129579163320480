import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import { catchError, filter, lastValueFrom, map, throwError, timeout } from 'rxjs';
import { SignalRHttpClientWrapperOptions } from './signalr-http-client-wrapper.options';

export const defaultOptions: SignalRHttpClientWrapperOptions = {
	requestTimeout: 100000,
	protectedResources: []
};

export class SignalRHttpClientWrapper extends signalR.DefaultHttpClient {
	options: SignalRHttpClientWrapperOptions;

	constructor(
		private httpClient: HttpClient,
		options?: SignalRHttpClientWrapperOptions
	) {
		super(console);
		this.options = { ...defaultOptions, ...options };
	}

	override send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		if (this.shouldUseProtectedResource(request.url)) {
			return this.customSend(request);
		} else {
			return super.send(request);
		}
	}

	private shouldUseProtectedResource(url: string): boolean {
		return this.options.protectedResources.some((pattern) => this.matchUrlPattern(url, pattern));
	}

	private customSend(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		const httpRequest = this.mapHttpRequest(request);

		const response$ = this.httpClient.request<signalR.HttpResponse>(httpRequest).pipe(
			timeout(request.timeout ?? this.options.requestTimeout),
			filter((httpEvent) => httpEvent.type === HttpEventType.Response),
			map((httpEvent) => {
				const httpResponse = httpEvent as HttpResponse<signalR.HttpResponse>;
				return new signalR.HttpResponse(httpResponse.status, httpResponse.statusText, JSON.stringify(httpResponse.body));
			}),
			catchError((error) => {
				if (error.name === 'TimeoutError') {
					return throwError(() => new signalR.TimeoutError());
				}
				return throwError(() => error);
			})
		);

		return lastValueFrom(response$);
	}

	private mapHttpRequest(request: signalR.HttpRequest): HttpRequest<signalR.HttpResponse> {
		return new HttpRequest<any>(request.method, request.url, request.content, {
			headers: new HttpHeaders(request.headers),
			responseType: this.mapResponseType(request.responseType),
			withCredentials: false
		});
	}

	private mapResponseType(responseType?: XMLHttpRequestResponseType): ('arraybuffer' | 'blob' | 'json' | 'text') | undefined {
		switch (responseType) {
			case undefined:
			case '':
				return undefined;
			case 'document':
				return 'text';
			default:
				return responseType;
		}
	}

	private matchUrlPattern(url: string, pattern: string): boolean {
		const regexPattern = pattern.replace(/\*/g, '.*');
		return new RegExp(regexPattern).test(url);
	}
}
