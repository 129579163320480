import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalModule,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { MSALGuardConfigFactory } from './msal-guard-config.factory';
import { MSALInstanceFactory } from './msal-instance.factory';
import { MijndnbAuthModule, MijndnbAuthService } from '@dnb/mijndnb-auth';
import { environment } from 'src/environments/environment';
import { MSALInterceptorConfigFactory } from './msal-interceptor-config.factory';
import { InitializeMsalMessageHandler, MsalMessageHandlerService } from './msal-message-handler.service';
import { SignOutComponent } from './sign-out.component';
import { AuthBroadcastService, InitializeAuthBroadcastService } from './auth-broadcast.service';
import { LanguageService } from 'src/app/language.service';
import { InternalAuthGuard } from './internal/internal-auth-guard';

@NgModule({
	declarations: [SignOutComponent],
	imports: [
		CommonModule,
		MsalModule,
		MijndnbAuthModule.forRoot({
			idpFilter: environment.authenticationServer.idpFilter,
			mijnDnbBaseUrl: environment.mijndnb.mijnDNBBaseUri
		})
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
			deps: [LanguageService]
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		{
			provide: APP_INITIALIZER,
			useFactory: InitializeMsalMessageHandler,
			deps: [MsalMessageHandlerService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: InitializeAuthBroadcastService,
			deps: [AuthBroadcastService],
			multi: true
		},
		MsalService,
		MsalGuard,
		InternalAuthGuard,
		MsalBroadcastService,
		MijndnbAuthService
	]
})
export class AuthModule {}
