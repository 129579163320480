import { Inject, Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { IHttpClient } from 'src/app/core/http/ihttp-client';
import { Feedback, IOrganisationDetails, IOrganisationMenu, httpHandleError } from '../models';
import { CONFIG } from 'src/app/core/config';
import { MonitoringService } from './monitoring.service';
import { environment } from 'src/environments/environment';
import { IHTTP_CLIENT } from 'src/app/core/http/token';

@Injectable({
	providedIn: 'root'
})
export class OrganisationDetailsService {
	constructor(
		@Inject(IHTTP_CLIENT) private httpClient: IHttpClient,
		private monitoringService: MonitoringService
	) {}

	getOrganisationMenu(): Observable<IOrganisationMenu> {
		const url = `${environment.apiUrl}${CONFIG.baseUrls.organisation}/menu`;

		return this.httpClient.get<IOrganisationMenu>(url).pipe(catchError(httpHandleError(this.monitoringService)));
	}

	getOrganisationDetails(): Observable<IOrganisationDetails> {
		const url = `${environment.apiUrl}${CONFIG.baseUrls.organisation}/details`;

		return this.httpClient.get<IOrganisationDetails>(url).pipe(catchError(httpHandleError(this.monitoringService)));
	}

	updateOrganisationDetails(updateOrganisationMessage: any): Observable<any> {
		const url = `${environment.apiUrl}${CONFIG.baseUrls.organisation}/details`;

		this.monitoringService.logEvent('Organisationdetails', {
			update: 'updateOrganisation'
		});

		return this.httpClient.put(url, updateOrganisationMessage).pipe(catchError(httpHandleError(this.monitoringService)));
	}

	submitFeedback(feedback: Feedback): Observable<any> {
		const url = `${environment.apiUrl}${CONFIG.baseUrls.organisation}/feedback`;

		this.monitoringService.logEvent('organisationDetails', {
			submit: 'feedback'
		});

		return this.httpClient.post(url, feedback).pipe(catchError(httpHandleError(this.monitoringService)));
	}
}
