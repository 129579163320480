<div mat-dialog-content>
    <ng-container *ngIf="loading; else errorTemplate">
        <div class="query">
            <ng-container>
                {{ 'ORGANISATION_DETAILS.AUTHENTICATION.REQUESTED' | translate}}
            </ng-container>
            <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
    </ng-container>
    <ng-container *ngIf="authenticated; else errorTemplate">
        <div class="query">
            <ng-container>
                {{ 'ORGANISATION_DETAILS.AUTHENTICATION.REDIRECTING' | translate}}
            </ng-container>
            <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
    </ng-container>
</div>

<ng-template #errorTemplate>
    <dlt-notification-box [text]="errorMessage"></dlt-notification-box>
</ng-template>