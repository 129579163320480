import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactChannelEditBaseComponent } from '../contact-channel-edit-base.component';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { IPhoneNumber, IOrganisationObject } from 'src/app/shared/models';
import { OrganisationEntityFormFactory } from 'src/app/shared/services/factories/organisation-entity-form.factory';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoBoxComponent, InfoButtonComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { OrganisationEntityEditPropertiesComponent } from '../../organisation-entity-edit-properties/organisation-entity-edit-properties.component';

@Component({
	selector: 'app-phone-number-contact-channel-edit',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, InfoBoxComponent, InfoButtonComponent, OrganisationEntityEditPropertiesComponent],
	templateUrl: './phone-number-contact-channel-edit.component.html'
})
export class PhoneNumberContactChannelEditComponent extends ContactChannelEditBaseComponent {
	@Input() contactChannel!: IPhoneNumber;

	constructor(private formFactory: OrganisationEntityFormFactory) {
		super();
	}

	protected getOrganisationObjectAfterInit(): IOrganisationObject {
		return this.contactChannel;
	}

	protected createFormAttributes(): FormAttribute[] {
		return this.formFactory.CreatePhoneNumberFormAttributes(this.contactChannel, true);
	}
}
