import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode, getTranslationTokenFromHttpStatusCode } from './http-status-code.models';

export class HttpServiceError extends HttpErrorResponse {
	translationToken: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(error: any, translationToken: string) {
		// /*Partial<HttpErrorResponse>*/
		super(error);
		this.translationToken = translationToken || getTranslationTokenFromHttpStatusCode(HttpStatusCode.Unknown);
	}
}
