import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BreadcrumbData, BreadcrumbItem } from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumbData';

	private readonly breadCrumbUpdated = new BehaviorSubject<BreadcrumbItem[]>([]);
	breadCrumbUpdated$ = this.breadCrumbUpdated.asObservable();

	breadCrumbTitle = signal('');

	createBreadcrumbs(activatedRoute: ActivatedRoute): BreadcrumbItem[] | null {
		const items = this.createBreadcrumbsInt(activatedRoute, '', [
			{ label: 'HOME.TITLE', url: environment.mijndnb.mijnDNBBaseUri, absoluteUrl: true }
		]);
		this.breadCrumbUpdated.next(items);
		return items;
	}

	private createBreadcrumbsInt(activatedRoute: ActivatedRoute, url = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] | null {
		const children: ActivatedRoute[] = activatedRoute.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		const child = children[0];
		const items: BreadcrumbItem[] | null = this.createBreadcrumbItemsForChildRoute(child, url, breadcrumbs);
		if (items === null) {
			// stop on null, breadcrum is disabled, no recursion needed.
			return null;
		}
		this.breadCrumbTitle.set(items[items.length - 1].label);
		return this.createBreadcrumbsInt(child, url, items);
	}

	private createBreadcrumbItemsForChildRoute(child: ActivatedRoute, url: string, allBreadcrumbs: BreadcrumbItem[]): BreadcrumbItem[] | null {
		const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
		if (routeURL !== '') {
			url += `/${routeURL}`;
		}

		const prop = child.snapshot.data[BreadcrumbService.ROUTE_DATA_BREADCRUMB];
		if (!prop) {
			// return existing items in order to add for next child(ren)
			return allBreadcrumbs;
		}

		const breadcrumbData: BreadcrumbData = { ...prop };
		if (breadcrumbData.disabled) {
			// return null to indicate no breadcrumb at all
			return null;
		}

		// push to array with all breadcrumb items with test for fixed url
		breadcrumbData?.items?.forEach((item) =>
			allBreadcrumbs.push({ label: item.label, url: item.url ? item.url : url, noPageHeader: item.noPageHeader })
		);

		return allBreadcrumbs;
	}
}
