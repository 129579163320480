import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Subject, finalize, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadSession } from '@dnb/mijndnb-ui';
import { ButtonAttribute, ErrorBoxComponent, FormsButtonComponent, NotificationBoxComponent, PermissionType } from '@dnb/supervision-core';
import { HideIfUnauthorizedDirective, HttpServiceError } from 'src/app/shared';
import { DOCUMENTS_SERVICE } from '../../dependency-injection';
import { IDocumentService } from '../../interfaces';
import {
	DocumentUploadModalContext,
	IDocumentTypeMap,
	IDocumentUploadRequest,
	IOption,
	UploadFormValues,
	UploadFormValuesRequired,
	generateYearOptions,
	initialUploadFormValues,
	initialUploadFormValuesRequired,
	mapToDocumentOptions,
	mapToDocumentTypes as mapToDocumentTypeMap,
	mapUploadFormValues,
	mapToRequiredFormValues,
	IDocument
} from '../../models';
import { DocumentUploadFormComponent } from '../document-upload-form/document-upload-form.component';
import { MetadataResponse } from "../../models/metadata-response.models";

@Component({
	selector: 'app-document-upload-modal',
	standalone: true,
	imports: [
		CommonModule,
		ErrorBoxComponent,
		NotificationBoxComponent,
		HideIfUnauthorizedDirective,
		FormsButtonComponent,
		MatDialogModule,
		DocumentUploadFormComponent,
		TranslateModule
	],
	templateUrl: './document-upload-modal.component.html',
	styleUrls: ['./document-upload-modal.component.scss']
})
export class DocumentUploadModalComponent implements OnDestroy {
	@ViewChild(DocumentUploadFormComponent) private uploadForm: DocumentUploadFormComponent;

	private readonly destroy$ = new Subject<void>();

	loading = true;
	loadError: string = '';
	submitting = false;
	submitError: string = '';

	uploadSession!: FileUploadSession;
	PermissionType = PermissionType;
	documentTypeMap: IDocumentTypeMap = {};

	formValues: UploadFormValues = initialUploadFormValues;
	formValuesRequired: UploadFormValuesRequired = initialUploadFormValuesRequired;
	documentTypeOptions: IOption[] = [];
	yearOptions: IOption[] = [];

	constructor(
		@Inject(DOCUMENTS_SERVICE) private documentService: IDocumentService,
		private dialogRef: MatDialogRef<DocumentUploadModalComponent>,
		@Inject(MAT_DIALOG_DATA) private dataContext: DocumentUploadModalContext
	) {}

	ngOnInit(): void {
		const { documentTypes, documentTypeName } = this.dataContext;

		this.documentTypeMap = mapToDocumentTypeMap(documentTypes);
		this.documentTypeOptions = mapToDocumentOptions(documentTypes);
		this.formValuesRequired = mapToRequiredFormValues(this.documentTypeMap[documentTypeName]);
		this.yearOptions = generateYearOptions(new Date().getFullYear());

		this.documentService
			.createFileUploadSession()
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => (this.loading = false))
			)
			.subscribe({
				next: (uploadSession) => {
					this.uploadSession = uploadSession;

					this.formValues = {
						...this.formValues,
						documentType: documentTypeName
					};
				},
				error: (error: Error) => {
					this.loadError = error instanceof HttpServiceError ? error.translationToken : error.message;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onValueChange(formValues: Partial<UploadFormValues>) {
		const { documentType: currentDocumentTypeName } = this.formValues;
		const { documentType: changedDocumentTypeName } = formValues;

		if (currentDocumentTypeName !== changedDocumentTypeName) {
			const currentDocumentType = this.documentTypeMap[changedDocumentTypeName];
			if (currentDocumentType) {
				this.formValuesRequired = mapToRequiredFormValues(currentDocumentType);
			}

			this.formValues = { ...this.formValues, ...formValues };
		}
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onSave(): void {
		this.uploadForm.submit();
	}

	onSubmit(formValues: Partial<UploadFormValues>) {
		const { relationId, hash } = this.dataContext;
		const { sessionId: uploadSessionId } = this.uploadSession;

		const documentData: IDocumentUploadRequest = {
			...mapUploadFormValues(formValues, this.formValuesRequired),
			hash,
			uploadSessionId
		};

		this.submitting = true;
		this.submitError = '';
		this.documentService
			.submitDocumentData(documentData, relationId)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => {
					this.submitting = false;
				})
			)
			.subscribe({
				next: (metadata: MetadataResponse) =>  {
					const newDoc: IDocument = {
						fileId: metadata.fileId,
						title: metadata.title,
						documentType: documentData.documentType,
						readyForDownload: false,
						uploadEnabled: true,
						deleteDocumentEnabled: false,
					}
					this.dialogRef.close(newDoc);
				},
				error: (error: Error) => {
					this.submitError = error instanceof HttpServiceError ? error.translationToken : error.message;
				}
			});
	}

	get cancelButtonAttribute(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'medium',
			iconLeft: 'fa fa-times',
			showIconLeft: true,
			text: 'FORM.CANCEL',
			disabled: this.submitting
		};
	}

	get saveButtonAttribute(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'medium',
			iconLeft: 'fa ' + (this.submitting ? 'fa-spin fa-spinner' : 'fa-save'),
			showIconLeft: true,
			text: 'FORM.SAVE',
			disabled: this.loading ? this.loading : this.submitting
		};
	}

	showLastDocumentInfo(documentType: string | null): boolean {
		const currentDocumentType = this.documentTypeMap[documentType];
		return currentDocumentType?.lastPeriodShown === 'LastDocument';
	}
}
