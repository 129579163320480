import { Injectable } from "@angular/core";
import { IInternalService } from "../..//interfaces";
import { HttpClient } from "@angular/common/http";
import { Observable, catchError } from "rxjs";
import { IInternalAuthenticationRequest, IInternalAuthenticationResult } from "../../models";
import { MonitoringService, httpHandleError } from "src/app/shared";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class InternalService implements IInternalService {

    constructor(private httpClient: HttpClient, private monitoringService: MonitoringService){}

    authenticate(request: IInternalAuthenticationRequest): Observable<IInternalAuthenticationResult> {
        const url = `${environment.apiUrl}/v1/organisationdetails/internal/authenticate`;

        return this.httpClient.post<IInternalAuthenticationResult>(url, request).pipe(catchError(httpHandleError(this.monitoringService)))
    }
}