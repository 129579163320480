import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { InteractionType } from '@azure/msal-browser';

export function InternalMSALGuardConfigFactory(): MsalGuardConfiguration {
	return <MsalGuardConfiguration>{
		interactionType: InteractionType.Redirect,
		authRequest: {
			extraQueryParameters: {
				idps: "aad-dnb"
			}
		}
	};
}

export const DLT_CORE_INTERNAL_MSAL_GUARD_CONFIG = new InjectionToken<MsalGuardConfiguration>('Custom internal guard config', {
	providedIn: 'root',
	factory: InternalMSALGuardConfigFactory
});

@Injectable()
export class InternalAuthGuard extends MsalGuard {
	constructor(
		@Inject(DLT_CORE_INTERNAL_MSAL_GUARD_CONFIG) internalGuardConfig: MsalGuardConfiguration,
		msalBroadcastService: MsalBroadcastService,
		msalService: MsalService,
		location: Location,
		router: Router
	) {
		super(internalGuardConfig, msalBroadcastService, msalService, location, router);
	}
}

