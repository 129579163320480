import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelAttribute } from '../../models/attributes/label-attribute.model';
import { EntityPropertyLabelComponent, InfoBoxComponent, InfoButtonComponent, MarkdownDirective } from '@dnb/supervision-core/src/lib/dlt-ui';
import { TranslateModule } from '@ngx-translate/core';
import { DateStringPipe } from '@dnb/supervision-core';
import { LanguageService } from 'src/app/language.service';

@Component({
	selector: 'app-organisation-entity-property',
	standalone: true,
	imports: [CommonModule, EntityPropertyLabelComponent, InfoBoxComponent, InfoButtonComponent, MarkdownDirective, DateStringPipe, TranslateModule],
	templateUrl: './organisation-entity-property.component.html',
	styleUrls: ['./organisation-entity-property.component.scss']
})
export class OrganisationEntityPropertyComponent {
	@Input() formAttribute!: LabelAttribute;
	@Input() layout: 'row' | 'column' = 'row';
	@Input() hasGlobalHelp = false;

	@Output() toggleGlobalHelp = new EventEmitter();

	showHelpInformation = false;

	constructor(private languageService: LanguageService) {}

	onToggleHelpInformation() {
		this.showHelpInformation = !this.showHelpInformation;
	}

	onToggleGlobalHelp() {
		this.toggleGlobalHelp.emit();
	}

	OnCloseHelpInformation() {
		this.showHelpInformation = false;
	}

	getFormat(): string {
		return this.languageService.getFormat;
	}
}
