import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBoxComponent } from '@dnb/supervision-core/src/lib/dlt-ui';
import { FormAttribute } from '@dnb/supervision-core/src/lib/dlt-forms';
import { OrganisationEntityPropertyComponent } from '../organisation-entity-property/organisation-entity-property.component';

@Component({
	selector: 'app-organisation-entity-properties',
	standalone: true,
	imports: [CommonModule, InfoBoxComponent, OrganisationEntityPropertyComponent],
	templateUrl: './organisation-entity-properties.component.html'
})
export class OrganisationEntityPropertiesComponent {
	@Input() formAttributes!: FormAttribute[];
	@Input() layout: 'row' | 'column' = 'row';
	@Input() globalHelpText!: string;

	showGlobalHelp = false;

	get hasGlobalHelp(): boolean {
		return this.globalHelpText != null;
	}

	onToggleGlobalHelp(): void {
		this.showGlobalHelp = !this.showGlobalHelp;
	}

	onCloseGlobalHelp(): void {
		this.showGlobalHelp = false;
	}
}
