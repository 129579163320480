import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from './authentication/auth.module';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule, AuthModule, TranslateModule, RouterModule],
	providers: []
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
