import { HttpEvent, HttpEventType, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpSentEvent } from '@angular/common/http';
import { Observable, filter, scan } from 'rxjs';

/**
 * Represents an event occurring during a file download operation.
 */
export interface DownloadProgressEvent {
	readonly status: 'started' | 'progress' | 'completed';
	progress: number;
	blob: Blob | null;
	fileName: string | null;
}

/**
 * Processes HTTP events related to file download with progress tracking.
 * @param totalSize Optional total size of the file being downloaded, in bytes.
 * @returns A function that takes an Observable of HttpEvent<Blob> and returns an Observable of DownloadEvent.
 */
export function trackDownloadProgress(totalSize?: number): (source: Observable<HttpEvent<Blob>>) => Observable<DownloadProgressEvent> {
	return (source: Observable<HttpEvent<Blob>>) =>
		source.pipe(
			filter((event) => isValidEvent(event)),
			scan(
				(acc: DownloadProgressEvent, currentEvent: HttpEvent<Blob>): DownloadProgressEvent => {
					if (isHttpHeaderResponse(currentEvent)) {
						const contentDisposition = currentEvent.headers.get('Content-Disposition');
						const fileName = parseContentDisposition(contentDisposition);

						const contentLength = currentEvent.headers.get('Content-Length');
						if (isValidInteger(contentLength)) {
							totalSize = parseInt(contentLength, 10);
						}

						acc = { ...acc, fileName };
					}

					if (isHttpSent(currentEvent)) {
						acc = { ...acc, status: 'started', progress: 0, blob: null };
					}

					if (isHttpProgressEvent(currentEvent)) {
						const { loaded, total } = currentEvent;
						const expectedSize = totalSize ?? total ?? 100;
						const progress = Math.round((100 * loaded) / expectedSize);

						console.log('Track download progress: ', loaded);

						acc = { ...acc, status: 'progress', progress, blob: null };
					}

					if (isHttpResponse(currentEvent)) {
						const { body: blob } = currentEvent;
						acc = { ...acc, status: 'completed', progress: 100, blob };
					}

					return acc;
				},
				{ status: 'started', progress: 0, blob: null, fileName: null }
			)
		);
}

function isValidEvent(event: HttpEvent<unknown>): boolean {
	return (
		event.type === HttpEventType.Sent ||
		event.type === HttpEventType.ResponseHeader ||
		event.type === HttpEventType.DownloadProgress ||
		event.type === HttpEventType.Response
	);
}

function isHttpHeaderResponse(event: HttpEvent<unknown>): event is HttpHeaderResponse {
	return event.type === HttpEventType.ResponseHeader;
}

function isHttpSent<T>(event: HttpEvent<T>): event is HttpSentEvent {
	return event.type === HttpEventType.Sent;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
	return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
	return event.type === HttpEventType.Response;
}

function parseContentDisposition(contentDisposition: string): string | null {
	const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	const matches = filenameRegex.exec(contentDisposition);
	if (matches && matches.length > 1) {
		return matches[1].replace(/['"]/g, '');
	}
	return null;
}

function isValidInteger(input: string | null | undefined): boolean {
	if (input === null || input === undefined || input.trim() === '') {
		return false;
	}

	const numberValue = parseInt(input, 10);
	return !isNaN(numberValue);
}
