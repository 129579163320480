export class ObjectHelperService {
	/**
	 * Creates a copy of the object passed without any array type properties. Used for FormGroup creation.
	 * @param obj
	 * @param beforeCopyCallBack allows an eternal check to decide if a property should be copied, return false to skip.
	 */
	public static CopyObjectWithoutArrayProperties(obj: any, beforeCopyCallBack?: (p: string) => boolean): any {
		const t: any = new Object();

		for (const prop in obj) {
			// must be own property, note that this will be all properties when using a class that extends a class
			// only when chaining prototypes, do we get a false on the hasOwnProperty method for properties in the underlying prototype
			if (!Object.prototype.hasOwnProperty.call(obj, prop) || (beforeCopyCallBack && beforeCopyCallBack(prop) === false)) {
				continue;
			}

			const v = obj[prop];
			// must not be an array property
			if (((Array.isArray && Array.isArray(v)) || v instanceof Array) != true) {
				t[prop] = v;
			}
		}
		return t;
	}

	/** you can also try Object.assign() or { ...source } syntax */
	public static CopyObjectPropertiesToObject(source: any, target: any): void {
		for (const prop in source) {
			// must be own property
			if (Object.prototype.hasOwnProperty.call(source, prop)) {
				target[prop] = source[prop];
			}
		}
	}

	/** finds an object by way of finding a match for either the object itself or one of its descendants */
	public static findRecursively<T>(startObject: T | null, childSelector: (n: T) => T[], matchFunc: (n: T) => boolean): T | null {
		if (!startObject) {
			return null;
		}

		if (matchFunc(startObject)) {
			return startObject;
		}

		const children = childSelector(startObject);
		if (!children) {
			return null;
		}

		for (let i = 0; i < children.length; i++) {
			const node = this.findRecursively(children[i], childSelector, matchFunc);
			if (node) {
				return node;
			}
		}

		return null;
	}
}
