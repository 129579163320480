<div [formGroup]="formGroup">
	<div class="row">
		<div class="col-xs-12">
			<dlt-info-button *ngIf="hasHelpInformation" (toggle)="onToggleHelpInformation()"></dlt-info-button>
		</div>
	</div>
	<dlt-info-box
		*ngIf="showHelpInformation"
		[content]="helpInformation"
		[translateContent]="true"
		(closeBox)="onCloseHelpInformation()"></dlt-info-box>
	<app-organisation-entity-edit-properties [formAttributes]="FormAttributes" [formGroup]="formGroup"> </app-organisation-entity-edit-properties>
</div>
