import { Observable, OperatorFunction } from 'rxjs';
import { NgZone } from '@angular/core';

/**
 * Custom OperatorFunction that makes sure that all lifecycle hooks of an Observable
 * are running in the NgZone.
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
	return (source) => {
		return new Observable((observer) => {
			const onNext = (value: T): void => {
				zone.run(() => observer.next(value));
			};
			const onError = (e: any): void => {
				zone.run(() => observer.error(e));
			};
			const onComplete = (): void => {
				zone.run(() => observer.complete());
			};
			return source.subscribe({ next: onNext, error: onError, complete: onComplete });
		});
	};
}
