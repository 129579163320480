import { Component, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-form-control-text',
	standalone: true,
	imports: [CommonModule, TranslateModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule],
	templateUrl: './form-control-text.component.html',
	styleUrls: ['./form-control-text.component.scss']
})
export class FormControlTextComponent {
	@Input({ required: true }) controlName: string;
	@Input() label: string | null = null;
	@Input() required: boolean = false;

	constructor(@Optional() private parentForm: FormGroupDirective) {
		if (!this.parentForm) {
			throw new Error('The app-form-control-text component requires a parent formGroup directive.');
		}
	}

	get formGroup(): FormGroup {
		return this.parentForm.form;
	}

	get control(): any {
		return this.formGroup.get(this.controlName);
	}

	get errorRequired(): string {
		return 'ERRORS.REQUIRED';
	}
}
