<form *ngIf="formGroup" [formGroup]="formGroup">
	<mat-form-field [hideRequiredMarker]="!label" [floatLabel]="'always'" [appearance]="'outline'">
		<!-- label -->
		<mat-label *ngIf="label">
			{{ label | translate }}
		</mat-label>

		<!-- select -->
		<mat-select [formControl]="control" [required]="required" [placeholder]="placeHolder | translate">
			<mat-option>{{ placeHolder | translate }}</mat-option>
			<mat-option *ngFor="let option of options" [value]="option.value">
				{{ option.viewValue }}
			</mat-option>
		</mat-select>

		<!-- error -->
		<mat-error *ngIf="control.hasError('required')">
			{{ errorRequired | translate }}
		</mat-error>
	</mat-form-field>
</form>
