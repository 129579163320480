import { Subject, Subscriber, Subscription } from 'rxjs';

/**
 * Class to observe a single entity. It differs from BehaviorSubject in that this Subject does not emit on subscribe if no value has been set.
 */
export class EntitySubject<T> extends Subject<T> {
	private _valueIsSet: boolean;
	private _value: T;

	constructor() {
		super();
		this._valueIsSet = false;
	}

	public _subscribe(subscriber: Subscriber<T>): Subscription {
		// hack for calling the internal method, as it went from deprecated in 6 to internal in 7
		const subscription = super.subscribe(subscriber);
		//super._subscribe(subscriber);

		if (this._valueIsSet && subscription && !subscription.closed) {
			subscriber.next(this._value);
		}
		return subscription;
	}

	override next(value: T): void {
		this._valueIsSet = true;
		this._value = value;

		super.next(value);
	}
}
