export const CONFIG = {
	baseUrls: {
		form: '/api/form',
		formView: '/api/formView',
		formSpecification: '/api/formSpecification',
		formAuthorization: '/api/formAuthorization',
		surveyAuthorization: '/api/surveyAuthorization',
		case: '/api/case',
		informationRequest: '/api/informationRequest',
		user: '/api/user',
		alert: '/api/alert',
		applicationStatus: '/api/application-status',
		upload: '/api/upload',
		connection: '/api/connection',
		invitation: '/api/invitation',
		organisation: '/v1/organisationdetails',
		resource: '/api/resources',
		token: '/api/token',
		session: '/api/session',
		monitoring: '/api/monitoring',
		caseMessage: '/api/casemessage',
		featureSettings: '/api/featuresettings',
		optionSet: '/api/optionsets',
		supervisionActivities: '/api/supervisionactivities',
		survey: '/api/survey',
		surveyLoginLight: '/api/surveyloginlight',
		internal: '/api/internal',
		internalSurveyorSurvey: '/api/internal/surveyor/survey',
		internalSurveyPreview: '/api/internal/surveyor/survey/preview-form'
	},
	baseMockUrls: {
		form: 'assets/mocks/form',
		user: 'assets/mocks/user',
		menu: 'assets/mocks/menu',
		organisationDetails: 'assets/mocks/organisationDetails'
	},
	useMocks: false,
	defaultLanguage: 'nl',
	signOutUrl: '{{LogoutUrl}}'
};
