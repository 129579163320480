import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppSidebarArrowComponent } from '../app-sidebar-arrow/app-sidebar-arrow.component';
import { SidebarFeatures, initialSidebarFeatures } from '../../models';

@Component({
	selector: 'app-sidebar',
	standalone: true,
	imports: [CommonModule, AppSidebarArrowComponent, RouterModule, TranslateModule],
	templateUrl: './app-sidebar.component.html'
})
export class AppSidebarComponent {
	@Input() features: SidebarFeatures = initialSidebarFeatures;

	public featureAvailable(featureName: keyof SidebarFeatures): boolean {
		return this.features[featureName] === true;
	}
}
