import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { SignOutComponent } from './core/authentication/sign-out.component';
import { AppLayoutComponent } from './root/containers/app-layout/app-layout.component';
import { InternalAuthGuard } from './core/authentication/internal/internal-auth-guard';
import { InternalComponent } from './internal/containers';

export const AppRoutes: Routes = [
	{
		// b2c redirect endpoint
		path: 'auth',
		component: MsalRedirectComponent
	},
	{
		path: 'sign-out',
		component: SignOutComponent
	},
	{
		path: 'organisation',
		component: AppLayoutComponent,
		data: { breadcrumbData: { items: [{ label: 'SUPMOG.TITLE', url: null, noPageHeader: true }] } },
		children: [
			{
				path: 'home',
				canActivate: [MsalGuard],
				loadChildren: () => import('./home/home.routing').then((mod) => mod.HomeRoutes)
			},
			{
				path: 'documents',
				canActivate: [MsalGuard],
				loadChildren: () => import('./documents/documents.routing').then((mod) => mod.DocumentsRoutes)
			},
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full'
			},
			{
				path: 'details',
				canActivate: [MsalGuard],
				loadChildren: () => import('./details/details.routing').then((mod) => mod.DetailsRoutes)
			},
			{
				path: 'feedback',
				canActivate: [MsalGuard],
				loadChildren: () => import('./feedback/feedback.routing').then((mod) => mod.FeedbackRoutes)
			},
			{
				path: 'shareholders',
				canActivate: [MsalGuard],
				loadChildren: () => import('./shareholders/shareholders.routing').then((mod) => mod.ShareholderRoutes)
			}
		]
	},
	{
		path: 'internal/:companyIdType/:companyId',
		canActivate: [InternalAuthGuard],
		component: InternalComponent
	},
	{
		path: '',
		redirectTo: 'organisation',
		pathMatch: 'full'
	}
];
