<div class="component-main-content">
	<ng-container *ngIf="!error; else errorTemplate">
		<ng-container *ngIf="initialized; else initializingTemplate">
			<div class="intro-text">
				<dlt-markdown text="HLPMOG-DOC-MERGE" [translateText]="true"></dlt-markdown>
			</div>

			<form [formGroup]="dummyform">
				<div class="form-group">
					<dlt-dropdown
						[placeholder]="'DOCUMENTS.FILTER_ON_TYPE'"
						[dropdownOptions]="documentTypeFilterOptions"
						[selectedDropDownOptionId]="selectedDocumentType"
						(dropdownOptionChanged)="onDocumentTypeFilterChanged($event)"
						[formGroup]="dummyform"
						[controlName]="'filter'">
					</dlt-dropdown>
				</div>
			</form>
			
			<app-live-update-notification [state]="hubState$ | async" [error]="hubError$ | async"/>

			<dlt-entity-table
				[entities]="documents() | documentsFilter: selectedDocumentType"
				[headerTemplate]="headerTemplate"
				[entityTemplate]="documentTemplate">
			</dlt-entity-table>		
		</ng-container>
	</ng-container>
</div>

<ng-template #errorTemplate>
	<dlt-notification-box [text]="error"></dlt-notification-box>
</ng-template>

<ng-template #initializingTemplate>
	<dlt-loading-data-indicator/>
</ng-template>

<ng-template #headerTemplate>
	<tr>
		<th class="" scope="col">{{ 'ORGANISATION_DETAILS.DOCUMENTS.HEADERS.DOCUMENTTYPE' | translate }}</th>
		<th class="" scope="col">{{ 'ORGANISATION_DETAILS.DOCUMENTS.HEADERS.TITLE' | translate }}</th>
		<th style="padding-right: 20px" class="nowrap" scope="col">{{ 'ORGANISATION_DETAILS.DOCUMENTS.HEADERS.DATE' | translate }}</th>
		<th class="" scope="col">{{ 'ORGANISATION_DETAILS.DOCUMENTS.HEADERS.EXTERNAL_REFERENCE' | translate }}</th>
		<ng-container *hideIfUnauthorized="PermissionType.OrganisationContribute">
			<th class="" *ngIf="!compactLayout" scope="col">
				<!--download-->
			</th>
		</ng-container>
		<th class="" *ngIf="!compactLayout" scope="col">
			<!--upload (new)-->
		</th>
		<th class="" *ngIf="!compactLayout" scope="col">
			<!--delete-->
		</th>
	</tr>
</ng-template>

<ng-template #documentTemplate let-document="entity">
	<tr>
		<td>
			{{ document.documentType }}
		</td>
		<td style="padding-right: 20px">
			<span *ngIf="document.fileId">
				{{ document.title }}
			</span>
			<span *ngIf="!document.fileId" class="text-danger">
				{{ 'ORGANISATION_DETAILS.DOCUMENTS.NO_FILE' | translate }}
			</span>
		</td>
		<td>
			{{ document.submittedOn | dltStringDate : getFormat() }}
		</td>
		<td>
			{{ document.externalReference }}
		</td>
		<ng-container *hideIfUnauthorized="PermissionType.OrganisationContribute">
			<td *ngIf="!compactLayout">
				<app-document-download-button 
					[document]="document" 
					(clicked)="onDownloadDocument(document)" />
			</td>
		</ng-container>

		<td *ngIf="!compactLayout">
			<app-document-upload-button 
				*ngIf="document.uploadEnabled"
				[documentType]="document.documentType" 
				[firstUpload]="!document.fileId" 
				(clicked)="onUploadDocument($event)" />
		</td>

		<td *ngIf="!compactLayout">
			<app-document-delete-button 
				*ngIf="document.deleteDocumentEnabled"
				[document]="document" 
				(clicked)="onDeleteDocument(document)"
				[size]="size" />
		</td>
	</tr>

	<tr *ngIf="compactLayout">
		<td colspan="4">
			<app-document-download-button
				[document]="document"
				(clicked)="onDownloadDocument(document)"/>
			
			<app-document-upload-button 
				*ngIf="document.uploadEnabled"
				[documentType]="document.documentType" 
				[firstUpload]="!document.fileId" 
				(clicked)="onUploadDocument($event)" />

			<app-document-delete-button 
				*ngIf="document.deleteDocumentEnabled"
				[document]="document" 
				(clicked)="onDeleteDocument(document)"
				[size]="size" />
		</td>
	</tr>	
</ng-template>