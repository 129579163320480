import { Injectable } from '@angular/core';
import { AuthError } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DigiDFlowHandlerService {
	private readonly _userCancelledSubject = new BehaviorSubject<boolean | null>(null);
	private readonly _authenticationFailedSubject = new BehaviorSubject<boolean | null>(null);
	private _lastStatusMessage: string = '';

	userCancelled$ = this._userCancelledSubject.asObservable();
	authenticationFailed$ = this._authenticationFailedSubject.asObservable();

	handle(event: AuthError): void {
		const invalidResponseReceivedCode = 'AADB2C90273';
		const userCancelledSubstring = 'Error Description: The user cancelled';

		if (event.message.includes(invalidResponseReceivedCode) && event.message.includes(userCancelledSubstring)) {
			this._lastStatusMessage = 'Het inloggen is geannuleerd.';
			this._userCancelledSubject.next(true);
		} else {
			this._lastStatusMessage =
				'Er is een fout opgetreden in de communicatie met DigiD. Probeert u het later nogmaals. Indien deze fout blijft aanhouden, kijk dan op de website https://www.digid.nl  voor de laatste informatie.';
			this._authenticationFailedSubject.next(true);
		}
	}

	lastStatusMessage(): string {
		return this._lastStatusMessage;
	}
}
