import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAttribute, FormsButtonComponent } from '@dnb/supervision-core';
import { IDocument } from '../../models';

@Component({
	selector: 'app-document-download-button',
	standalone: true,
	imports: [CommonModule, FormsButtonComponent],
	templateUrl: './document-download-button.component.html'
})
export class DocumentDownloadButtonComponent {
	@Input() document!: IDocument;
	@Output() clicked = new EventEmitter<IDocument>();

	get canDownload(): boolean {
		return this.document?.readyForDownload;
	}

	get ButtonAttribute(): ButtonAttribute {
		return <ButtonAttribute>{
			size: 'extra-small',
			disabled: this.document.isDownloading,
			iconLeft: 'fa ' + (this.document.isDownloading ? 'fa-spin fa-spinner' : 'fa-download'),
			showIconLeft: true,
			tooltip: 'ORGANISATION_DETAILS.DOCUMENTS.DOWNLOAD'
		};
	}

	onClick(): void {
		this.clicked.emit(this.document);
	}
}
