import { Injectable } from '@angular/core';
import { Observable, delay, of, tap } from 'rxjs';
import { IUserPermissionsService } from '../interfaces';
import { PermissionType } from '@dnb/supervision-core/src/lib/dlt-ui';

@Injectable({
	providedIn: 'root'
})
export class UserPermissionsMockedService implements IUserPermissionsService {
	private userPermissions: PermissionType[] = [];
	private isInitialized: boolean = false;

	initialize(): Observable<PermissionType[]> {
		const mockPermissions: PermissionType[] = [PermissionType.Cases, PermissionType.OrganisationView, PermissionType.OrganisationContribute];

		return of(mockPermissions).pipe(
			delay(500),
			tap(() => {
				this.userPermissions = [...mockPermissions];
				this.isInitialized = true;
			})
		);
	}

	hasPermission(permissionType: PermissionType): boolean {
		if (!this.isInitialized) {
			throw new Error('User permissions not initialized. Call initialize first.');
		}

		return this.userPermissions.includes(permissionType);
	}
}
