import { Injectable } from '@angular/core';
import { Observable, delay, map, of, shareReplay } from 'rxjs';
import { IOrganisationProfileService } from '../interfaces';
import { IOrganisationProfile } from 'src/app/root/models';

@Injectable({
	providedIn: 'root'
})
export class OrganisationProfileMockedService implements IOrganisationProfileService {
	private profile$: Observable<IOrganisationProfile>;

	constructor() {
		const mockedResponse: IOrganisationProfile = getProfileMockedResponse();
		this.profile$ = of(mockedResponse).pipe(delay(500), shareReplay(1));
	}

	getOrganisationProfile(): Observable<IOrganisationProfile> {
		return this.profile$;
	}

	getOrganisationProfileHash(): Observable<string> {
		return this.profile$.pipe(map((profile) => profile.hash));
	}
}

function getProfileMockedResponse(): IOrganisationProfile {
	return {
		hash: '708aefa8d61ae70855cec36754246ebcf13fbb9ba407c1278a94b46c052f8f97',
		mogProfileName: 'BOBI1',
		shareholderStructureEnabled: false,
		hasDocumentUpload: true
	};
}
